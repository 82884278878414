import { useEffect, useState } from "react";
import { printToFileAsync } from "expo-print";
import { shareAsync } from "expo-sharing";

export const useShare = () => {
  const [html, setHtml] = useState<string>("");

  const share = (html: string) => {
    setHtml(html);
  };

  useEffect(() => {
    const print = async () => {
      if (html.length == 0) return;
      const pdf = await printToFileAsync({
        html,
        height: 842,
        width: 595,
        margins: {
          top: 36,
          bottom: 36,
          left: 36,
          right: 36
        }
      });
      await shareAsync(pdf.uri);
    };
    print();
    setHtml("");
  }, [html]);

  return { html, share };
};
