import { useDSSubscriber } from "./useDSSubscriber";

let showHeaders = true;

export const useShowHeaders = () => {
  const { dispatch } = useDSSubscriber();

  function setShowHeaders(value: boolean) {
    showHeaders = value;
    dispatch("ShowHeaders");
  }

  function toggleShowHeaders() {
    showHeaders = !showHeaders;
    dispatch("ShowHeaders");
  }

  function getShowHeaders() {
    return showHeaders;
  }

  return { getShowHeaders, toggleShowHeaders, setShowHeaders };
};
