import { useMemo, useState } from "react";
import { Text, View } from "react-native";
import { styles } from "./styles";
import BaseQuestion from "./BaseQuestion";
import { useTextSize } from "@hooks/useTextSize";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import { Question } from "@entities/index";
import MathRenderer from "./MathRenderer";
import { hasLatex } from "@lib/commonChecks";

export default function MultipleChoice({ question }: { question: Question }) {
  const { listen } = useDSSubscriber();

  const { getFontSize } = useTextSize();
  const [fontSizeState, setFontSizeState] = useState<number>(getFontSize());
  const dynamicStyles = useMemo(() => {
    return {
      fontSize: fontSizeState,
      lineHeight: fontSizeState * 1.6
    };
  }, [fontSizeState]);
  listen("FontSize", () => setFontSizeState(getFontSize()));

  return (
    <BaseQuestion question={question}>
      {question.options?.map((option, index) => (
        <View key={index} style={styles.mc}>
          <Text style={{ ...styles.unordered_bullet, ...dynamicStyles }}>
            ◯
          </Text>
          {hasLatex(option) ? (
            <MathRenderer text={option} fontSize={fontSizeState} />
          ) : (
            <Text style={{ ...styles.text, ...dynamicStyles }}>{option}</Text>
          )}
        </View>
      ))}
    </BaseQuestion>
  );
}
