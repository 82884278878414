import { Button, TextInput, Checkbox, Text } from "react-native-paper";
import { Keyboard, StyleSheet, View } from "react-native";
import BaseModal from "@components/questions/BaseModal";
import { Dispatch, SetStateAction } from "react";

export default function PaperRegenerationModal({
  visible,
  setConfirmationVisible,
  setPaperRegenerating,
  additionalPrompt,
  setAdditionalPrompt,
  retainAnswer,
  setRetainAnswer,
  retainQuestionType,
  setRetainQuestionType
}: {
  visible: boolean;
  setConfirmationVisible: Dispatch<SetStateAction<boolean>>;
  setPaperRegenerating: Dispatch<SetStateAction<boolean>>;
  additionalPrompt: string;
  setAdditionalPrompt: Dispatch<SetStateAction<string>>;
  retainAnswer: boolean;
  setRetainAnswer: Dispatch<SetStateAction<boolean>>;
  retainQuestionType: boolean;
  setRetainQuestionType: Dispatch<SetStateAction<boolean>>;
}) {
  const styles = StyleSheet.create({
    Text: {
      textAlign: "center",
      alignSelf: "center",
      gap: 20,
      flex: 1,
      marginVertical: 4
    },
    Buttons: {
      flexDirection: "row",
      justifyContent: "center",
      gap: 32
    },
    Checkbox: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 25,
      marginVertical: 4
    }
  });

  return (
    <BaseModal
      title='How would you like to regenerate your paper?'
      visible={visible}
      setVisible={setConfirmationVisible}
    >
      <View style={styles.Checkbox}>
        <Text>Retain Answer</Text>
        <Checkbox
          status={retainAnswer ? "checked" : "unchecked"}
          onPress={() => {
            setRetainAnswer(!retainAnswer);
          }}
        />
      </View>
      <View style={styles.Checkbox}>
        <Text>Retain Question Type</Text>
        <Checkbox
          status={retainQuestionType ? "checked" : "unchecked"}
          onPress={() => {
            setRetainQuestionType(!retainQuestionType);
          }}
        />
      </View>
      <TextInput
        mode='outlined'
        label='Additional Criteria (optional)'
        defaultValue={additionalPrompt}
        onChangeText={text => setAdditionalPrompt(text)}
        multiline={true}
        contentStyle={{ padding: 0, marginVertical: 15, verticalAlign: "auto" }}
        style={{ height: 40, paddingHorizontal: 0 }}
        blurOnSubmit={true}
        onSubmitEditing={() => Keyboard.dismiss()}
      />
      <View style={styles.Buttons}>
        <Button
          mode='contained'
          onPress={() => {
            setPaperRegenerating(true);
            setConfirmationVisible(false);
            setAdditionalPrompt(additionalPrompt);
            setRetainAnswer(retainAnswer);
            setRetainQuestionType(retainQuestionType);
          }}
        >
          Confirm
        </Button>
        <Button
          mode='outlined'
          onPress={() => {
            setConfirmationVisible(false);
          }}
        >
          Cancel
        </Button>
      </View>
    </BaseModal>
  );
}
