import { CompositeNavigationProps } from "@components/navigations/types";
import { Paper, PaperStatus } from "@entities/index";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import Container from "typedi";
import { DataSource } from "typeorm";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { Swipeable } from "react-native-gesture-handler";
import { IconButton, Text, useTheme } from "react-native-paper";
import { SetStateAction, Dispatch } from "react";

export default function PaperSectionListItem({
  paper,
  isSelected,
  setSelected
}: {
  paper: Paper;
  isSelected?: boolean;
  setSelected?: Dispatch<SetStateAction<number | undefined>>;
}) {
  const theme: ReactNativePaper.Theme = useTheme();
  const navigation: CompositeNavigationProps = useNavigation();
  const { dispatch } = useDSSubscriber();

  const handleDelete = async (id: number) => {
    // TODO: show undo confirmation dialog before delete
    const datasource = Container.get(DataSource);
    const paper = await datasource
      .getRepository(Paper)
      .findOne({ where: { id } });
    if (!paper) return;
    paper!.status = PaperStatus.Archived;
    await datasource.getRepository(Paper).save(paper);
    dispatch("Papers");
  };

  return (
    <Swipeable
      renderRightActions={() => (
        <View style={styles.deleteButtonContainer}>
          <IconButton
            icon='archive'
            iconColor={theme.colors.white}
            size={24}
            onPress={() => handleDelete(paper.id)}
          />
        </View>
      )}
    >
      <TouchableHighlight
        style={[
          styles.listItemContainer,
          isSelected && { backgroundColor: "#ffffff20" }
        ]}
        onPressOut={() => {
          navigation.navigate("PaperScreen", {
            paperId: paper.id,
            title: paper.title
          });
          setSelected && setSelected(paper.id);
        }}
        underlayColor='#ffffff16'
      >
        <View style={styles.container}>
          <View style={[styles.row, { gap: 8 }]}>
            <Text style={styles.title} numberOfLines={1}>
              {paper.title}
            </Text>
            <Text style={styles.date}>
              {dayjs(paper.createdAt).format("DD MMM YYYY")}
            </Text>
          </View>
          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text style={styles.details}>
                {paper.subject} / {paper.level}
              </Text>
            </View>
          </View>
        </View>
      </TouchableHighlight>
    </Swipeable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "space-between"
  },
  listItemContainer: {
    width: "100%",
    padding: 16
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  title: {
    fontFamily: "PoppinsRegular",
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
    flex: 1
  },
  date: {
    color: "white",
    fontSize: 12,
    fontFamily: "PoppinsRegular",
    opacity: 0.6
  },
  details: {
    fontFamily: "PoppinsRegular",
    color: "white",
    opacity: 0.6
  },
  deleteButtonContainer: {
    backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center"
  }
});
