import { StyleSheet, View } from "react-native";
// import Message from "./Message";
import { MessageType, User } from "../../types";
import { useEffect, useRef } from "react";
import { List, useTheme } from "react-native-paper";
import Message from "./Message";

interface Props {
  messages: (MessageType.Text | MessageType.Image)[];
  user: User;
}

export default function MessageBoard({ messages, user }: Props) {
  const theme: ReactNativePaper.Theme = useTheme();

  const styles = StyleSheet.create({
    section: {
      padding: 0,
      margin: 0
    },
    subheader: {
      marginTop: 0,
      marginBottom: 10,
      fontSize: 12,
      fontFamily: "PoppinsRegular",
      color: theme.colors.text2,
      textAlign: "center"
    }
  });

  return (
    <List.Section style={styles.section}>
      <List.Subheader style={styles.subheader}>Today</List.Subheader>
      {messages.map(m => (
        <Message key={m.id} message={m} isMe={m.author.id === user.id} />
      ))}
    </List.Section>
  );
}
