import { View } from "react-native";
import { Section } from "@entities/index";

export default function ListOfQuestions({ section }: { section: Section }) {
  return (
    <>
      {section.questions.map(question => (
        <View style={{ flexShrink: 1, width: "100%" }} key={question.id}>
          {question.toJSX()}
        </View>
      ))}
    </>
  );
}
