import { ReactNode } from "react";
import { Text, useTheme } from "react-native-paper";
import MathFormula from "../../formatter/MathFormula";

function formatContent(content: string | ReactNode, color = "black") {
  console.log(content);
  if (typeof content !== "string") {
    return [content];
  }

  const inlineRegExp = /\$(.*?)\$/g;
  // split content into list of strings and math formulas
  const list: any[] = [];
  let match;
  let lastIndex = 0;
  while ((match = inlineRegExp.exec(content)) !== null) {
    const [fullMatch, formula] = match;
    const index = match.index;
    if (index > lastIndex) {
      list.push(content.substring(lastIndex, index));
    }
    list.push(
      <MathFormula key={`content${index}`} color={color}>
        {formula}
      </MathFormula>
    );
    lastIndex = index + fullMatch.length;
  }
  if (lastIndex < content.length) {
    list.push(content.substring(lastIndex));
  }

  return list;
}

export default function MessageTextFormatter({
  style,
  children,
  color = "black"
}) {
  const theme: ReactNativePaper.Theme = useTheme();
  const contentList: any[] = formatContent(children, color);
  return <Text style={{ ...style, color }}>{contentList}</Text>;
}
