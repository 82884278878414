import { createStackNavigator } from "@react-navigation/stack";
import ChatScreen from "@screens/ChatScreen";
import PaperListScreen from "@screens/PaperListScreen";
import PaperScreen from "@screens/PaperScreen";
import NoPaperScreen from "@screens/NoPaperScreen";
import React from "react";
import PaperScreenButtons from "./PaperScreenButtons";
import { RootStackParamList } from "./types";
import { IconButton, useTheme } from "react-native-paper";
import useIsLargeScreen from "@hooks/useIsLargeScreen";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { CompositeNavigationProps } from "./types";
import { Platform } from "react-native";

const Stack = createStackNavigator<RootStackParamList>();

const PaperStackNavigator = () => {
  const theme: ReactNativePaper.Theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const navigation: CompositeNavigationProps = useNavigation();
  return (
    <Stack.Navigator
      initialRouteName={!isLargeScreen ? "PaperListScreen" : "NoPaperScreen"}
      screenOptions={() => ({
        headerStyle: { backgroundColor: theme.colors.black },
        headerTintColor: theme.colors.white
      })}
    >
      {!isLargeScreen && (
        <Stack.Screen
          name='PaperListScreen'
          component={PaperListScreen}
          options={() => ({
            title: "Your Papers",
            headerShown: true
          })}
        />
      )}
      <Stack.Screen
        name='NoPaperScreen'
        component={NoPaperScreen}
        options={() => ({
          title: "No selected paper",
          headerShown: true
        })}
      />
      <Stack.Screen
        name='PaperScreen'
        component={PaperScreen}
        options={({ route }) => ({
          title: Platform.OS === "web" ? route.params?.title : "",
          headerShown: true,
          headerRight: () => <PaperScreenButtons />,
          headerLeft: isLargeScreen
            ? () => <></>
            : () => (
                <IconButton
                  icon={() => (
                    <AntDesign
                      name='arrowleft'
                      color={theme.colors.white}
                      size={28}
                    />
                  )}
                  iconColor={theme.colors.primary}
                  size={28}
                  onPress={() => navigation.goBack()}
                />
              )
        })}
      />
      <Stack.Screen
        name='ChatScreen'
        component={ChatScreen}
        options={() => ({
          headerShown: true
        })}
      />
    </Stack.Navigator>
  );
};
export default PaperStackNavigator;
