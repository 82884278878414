import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  question: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1
  },
  text: {
    flexWrap: "wrap",
    marginVertical: 4,
    flexShrink: 1
  },
  answer: {
    color: "#aaaaaa"
    // Disabling this for now because math expressions do not support italic style
    // and we want to unify the styles.
    // fontStyle: 'italic'
  },
  mc: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  ordered_bullet: {
    alignSelf: "flex-start",
    marginRight: 10,
    marginVertical: 4
  },
  unordered_bullet: {
    marginRight: 10,
    marginVertical: 4
  },
  line: {
    height: 1,
    backgroundColor: "#000",
    marginTop: 40
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginVertical: 4
  },
  choices: {
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 4,
    padding: 12,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    columnGap: 24,
    rowGap: 12,
    flexWrap: "wrap"
  },
  passage: {
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 4,
    maxWidth: "100%",
    padding: 12
  },
  instructions: {
    paddingBottom: 4
  }
});
