import { Paper, Question, Section } from "@entities/index";
import { Platform } from "react-native";
import { DataSource, DataSourceOptions } from "typeorm";

const isWeb = Platform.OS === "web";
const options: DataSourceOptions = isWeb
  ? {
      type: "sqljs",
      entities: [Paper, Section, Question],
      synchronize: true,
      autoSave: true,
      location: "youcodia",
      useLocalForage: true,
      logging: true ? "all" : []
    }
  : {
      database: "youcodia",
      driver: require("expo-sqlite"),
      entities: [Paper, Section, Question],
      synchronize: true,
      type: "expo"
    };

// @ts-ignore
const connToDS = async () => {
  const dataSourceConn = new DataSource(options);
  try {
    await dataSourceConn.initialize();
    console.log("Data Source has been initialized!");
    return dataSourceConn;
  } catch (err) {
    console.error("Error during Data Source initialization", err);
  }
};

const dataSource = connToDS();

export default dataSource;
