import { Dispatch, SetStateAction, useState } from "react";
import { Button, HelperText, TextInput } from "react-native-paper";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import Container from "typedi";
import { DataSource } from "typeorm";
import BaseModal from "./BaseModal";
import { Keyboard, View } from "react-native";
import { Question } from "@entities/index";

export default function QuestionEditModal({
  question,
  visible,
  setVisible
}: {
  question: Question;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) {
  const { dispatch } = useDSSubscriber();
  const [newQuestion, setNewQuestion] = useState<string>(question.content);
  const [newOptions, setNewOptions] = useState<string[]>(
    question.options ?? []
  );
  const [newSolution, setNewSolution] = useState(question.solution)
  const [emptyError, setEmptyError] = useState(false);

  return (
    <BaseModal title='Edit question' visible={visible} setVisible={setVisible}>
      <TextInput
        mode='outlined'
        label='Question'
        defaultValue={question.content}
        onChangeText={text => setNewQuestion(text)}
        multiline={true}
        contentStyle={{ padding: 0, marginVertical: 12, verticalAlign: "top" }}
        style={{ height: 90, paddingHorizontal: 0 }}
        blurOnSubmit={true}
        onSubmitEditing={() => Keyboard.dismiss()}
      />
      <View>
        {question.options?.map((option, index) => (
          <TextInput
            key={index}
            mode='outlined'
            label={`Choice ${index + 1}`}
            defaultValue={option}
            onChangeText={text =>
              setNewOptions(
                newOptions.map((option, i) => (i === index ? text : option))
              )
            }
            multiline={true}
            blurOnSubmit={true}
            onSubmitEditing={() => Keyboard.dismiss()}
          />
        ))}
      </View>
      <TextInput
        mode='outlined'
        label='Solution'
        defaultValue={question.solution}
        onChangeText={text => setNewSolution(text)}
        multiline={true}
        contentStyle={{ padding: 0, marginVertical: 12, verticalAlign: "top" }}
        style={{ height: 90, paddingHorizontal: 0 }}
        blurOnSubmit={true}
        onSubmitEditing={() => Keyboard.dismiss()}
      />
      <View>
        {emptyError && (
          <HelperText type='error' padding='none'>
            The above fields cannot be empty!
          </HelperText>
        )}
      </View>
      <Button
        mode='contained'
        onPress={async () => {
          if (newQuestion === "" || newOptions.some(option => option === "")) {
            setEmptyError(true);
            return;
          }
          const datasource = Container.get(DataSource);
          question.content = newQuestion;
          question.options = newOptions;
          question.solution = newSolution;
          await datasource.getRepository(Question).save(question);
          dispatch("PaperDetails");
          setVisible(false);
        }}
      >
        Confirm
      </Button>
    </BaseModal>
  );
}
