import { Platform } from "react-native";
import InlineImage from "./InlineImage";
import { useTheme } from "react-native-paper";

export default function MathFormula({
  children,
  color = "black"
}: {
  children: string;
  color?: string;
}) {
  const theme: ReactNativePaper.Theme = useTheme();

  const bg = color === "black" ? "white" : theme.colors.primary.substring(1);

  let uri = `https://latex.codecogs.com/gif.image?\\bg{${bg}}\\dpi{100}{\\color{${color}}${children}}`;
  if (Platform.OS === "web") {
    uri = `https://latex.codecogs.com/gif.image?\\bg{${bg}}\\dpi{100}{\\color{${color}}${children}}`;
  }
  console.log("uri", uri);
  return <InlineImage source={uri}></InlineImage>;
}
