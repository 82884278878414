import { View, StyleSheet, Image } from "react-native";
import { Text } from "react-native-paper";

export default function NoPaperScreen() {
  // This screen should be exclusive to large screens only (i.e. the layout with permanent drawer)
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "#FEEDE4",
      justifyContent: "center",
      alignItems: "center",
      gap: 24
    },
    image: {
      width: 300,
      height: 200,
      borderRadius: 16
    },
    text: {
      fontFamily: "PoppinsMedium",
      paddingBottom: 64
    }
  });

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={{
          uri: "https://i.kym-cdn.com/photos/images/original/001/510/490/e7f.jpg"
        }}
      />
      <Text variant='headlineSmall' style={styles.text}>
        Please select or upload a paper from the left!
      </Text>
    </View>
  );
}
