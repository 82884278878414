import React, { useEffect, useState } from "react";
import { StyleSheet, Platform, PixelRatio } from "react-native";
import { Image as RNImage } from "react-native";
import { Image } from "expo-image";

// This component fixes a bug in React Native with <Image> component inside of
// <Text> components.
const InlineImage = ({ source }: { source: string }) => {
  // get size of image
  const [dimension, setDimension] = useState([0, 0]);
  useEffect(() => {
    RNImage.getSize(source, (width, height) => {
      setDimension([width, height]);
    });
  }, []);

  const [width, height] = dimension;
  if (width === 0 || height === 0) {
    return null;
  }
  const style = { width, height };
  if (Platform.OS === "web") {
    style.display = "inline-block";
  }
  return (
    <Image
      style={style}
      source={source}
      contentFit='contain'
      contentPosition='center'
      transition={1000}
    />
  );
};

export default InlineImage;
