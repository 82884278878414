import { MathJax, MathJaxContext } from "better-react-mathjax";
import { Platform } from "react-native";
import { MathJaxSvg } from "react-native-mathjax-html-to-svg";

export default function MathRenderer({
  text,
  fontSize,
  color
}: {
  text: string;
  fontSize: number;
  color?: string;
}) {
  const isPlatformWeb = Platform.OS === "web";
  if (isPlatformWeb) {
    return (
      <MathJaxContext>
        <MathJax
          inline={true}
          key={JSON.stringify(fontSize)}
          style={{ fontSize, color }}
        >
          {text}
        </MathJax>
      </MathJaxContext>
    );
  }
  return (
    <MathJaxSvg
      key={JSON.stringify(fontSize)}
      fontSize={fontSize}
      color={color}
    >
      {text}
    </MathJaxSvg>
  );
}
