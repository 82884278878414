import { Paper } from "@entities/index";
import { View } from "react-native";
import PaperHeader from "@components/papers/PaperHeader";

export default function PaperInView({
  paper,
  showHeaders
}: {
  paper: Paper;
  showHeaders: boolean;
}) {
  if (!paper) return null;

  return (
    <View>
      {showHeaders && <PaperHeader paper={paper} />}
      {paper.toJSX()}
    </View>
  );
}
