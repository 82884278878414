import { StyleSheet, View } from "react-native";
import { Button, Text } from "react-native-paper";
import { Question } from "@entities/index";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import Container from "typedi";
import { DataSource } from "typeorm";
import BaseModal from "./BaseModal";
import { Dispatch, SetStateAction } from "react";

export default function QuestionDeleteModal({
  question,
  visible,
  setVisible
}: {
  question: Question;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) {
  const { dispatch } = useDSSubscriber();
  const styles = StyleSheet.create({
    questionText: {
      textAlign: "center",
      fontSize: 14,
      fontFamily: "PoppinsRegular",
      color: "grey"
    },
    buttonContainer: {
      flexDirection: "row",
      justifyContent: "center",
      gap: 32
    }
  });

  const datasource = Container.get(DataSource);

  const handleDeleteQuestion = async (id: number) => {
    const question = await datasource
      .getRepository(Question)
      .find({ where: { id } });
    if (!question) {
      console.warn(
        "User tried to delete a question, but it could not be found in DB!"
      );
      return;
    }
    await datasource.getRepository(Question).remove(question);
    dispatch("FetchQuestionsOrder");
  };

  return (
    <BaseModal
      title='Are you sure you want to delete this question?'
      visible={visible}
      setVisible={setVisible}
    >
      <Text style={styles.questionText}>{question.content}</Text>
      <View style={styles.buttonContainer}>
        <Button
          mode='contained'
          onPress={async () => {
            await handleDeleteQuestion(question.id);
            setVisible(false);
          }}
        >
          Delete
        </Button>
        <Button mode='outlined' onPress={() => setVisible(false)}>
          Cancel
        </Button>
      </View>
    </BaseModal>
  );
}
