import { useDSSubscriber } from "@hooks/useDSSubscriber";

const fontSizes = [10, 12, 14, 16, 18, 20, 22, 24, 26, 28];
let selectedIndex = 2;

export const useTextSize = () => {
  const { dispatch } = useDSSubscriber();
  function cycleUpFontSize() {
    selectedIndex = (selectedIndex + 1) % fontSizes.length;
    dispatch("FontSize");
  }

  function cycleDownFontSize() {
    selectedIndex === 0
      ? (selectedIndex = fontSizes.length - 1)
      : selectedIndex--;
    dispatch("FontSize");
  }

  function increaseFontSize() {
    if (selectedIndex < fontSizes.length - 1) {
      selectedIndex++;
      dispatch("FontSize");
    }
  }

  function decreaseFontSize() {
    if (selectedIndex > 0) {
      selectedIndex--;
      dispatch("FontSize");
    }
  }

  function getFontSize() {
    return fontSizes[selectedIndex];
  }
  return {
    getFontSize,
    cycleUpFontSize,
    cycleDownFontSize,
    increaseFontSize,
    decreaseFontSize
  };
};
