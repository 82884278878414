import {
  Button,
  HelperText,
  Modal,
  Portal,
  Text,
  TextInput,
  useTheme
} from "react-native-paper";
import { StyleSheet, View } from "react-native";
import DropDown from "react-native-paper-dropdown";
import { useState } from "react";
import useIsLargeScreen from "@hooks/useIsLargeScreen";
import { PaperLevel, PaperSubject } from "@entities/index";

type PaperDetails = {
  title: string;
  subject: PaperSubject;
  level: PaperLevel;
};

export default function InputModal({
  visible,
  setPaperDetails,
  paperDetails,
  setModalVisible
}: {
  visible: boolean;
  setPaperDetails: (paperDetails: PaperDetails) => void;
  paperDetails: PaperDetails;
  setModalVisible: (visible: boolean) => void;
}) {
  const theme: ReactNativePaper.Theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const styles = StyleSheet.create({
    modalContainer: {
      backgroundColor: theme.colors.white,
      paddingHorizontal: 20,
      paddingVertical: 40,
      borderRadius: 10,
      gap: 24,
      width: isLargeScreen ? 500 : "90%",
      alignSelf: "center"
    },
    text: {
      textAlign: "center",
      fontFamily: "PoppinsSemiBold"
    }
  });

  const [emptyError, setEmptyError] = useState(false);
  const [paperTitle, setPaperTitle] = useState<string>(paperDetails.title);

  const [subjectOpen, setSubjectOpen] = useState(false);
  const [paperSubject, setPaperSubject] = useState<PaperSubject>(
    paperDetails.subject
  );
  const subjectItems = Object.values(PaperSubject).map(key => ({
    label: key,
    value: key
  }));

  const [levelOpen, setLevelOpen] = useState(false);
  const [paperLevel, setPaperLevel] = useState<PaperLevel>(paperDetails.level);
  const levelItems = Object.values(PaperLevel).map(key => ({
    label: key,
    value: key
  }));

  return (
    <Portal>
      <Modal
        visible={visible}
        contentContainerStyle={styles.modalContainer}
        theme={{ colors: { backdrop: "#ffffff50" } }}
        dismissable={false}
      >
        <Text variant='headlineSmall' style={styles.text}>
          Paper details
        </Text>
        <TextInput
          mode='outlined'
          label='Paper Title'
          value={paperTitle}
          onChangeText={text => {
            setPaperTitle(text);
            setEmptyError(false);
          }}
        />
        <DropDown
          mode='outlined'
          label='Subject'
          list={subjectItems}
          visible={subjectOpen}
          showDropDown={() => setSubjectOpen(true)}
          onDismiss={() => setSubjectOpen(false)}
          value={paperSubject}
          setValue={text => {
            setPaperSubject(text);
            setEmptyError(false);
          }}
        />
        <DropDown
          mode='outlined'
          label='Level'
          list={levelItems}
          visible={levelOpen}
          showDropDown={() => setLevelOpen(true)}
          onDismiss={() => setLevelOpen(false)}
          value={paperLevel}
          setValue={text => {
            setPaperLevel(text);
            setEmptyError(false);
          }}
        />
        <View>
          {emptyError && (
            <HelperText type='error' padding='none'>
              The above fields cannot be empty!
            </HelperText>
          )}
        </View>
        <Button
          mode='contained'
          onPress={() => {
            if (paperTitle === "") {
              setEmptyError(true);
              return;
            }
            setPaperDetails({
              title: paperTitle,
              subject: paperSubject,
              level: paperLevel
            });
            setModalVisible(false);
          }}
        >
          Generate!
        </Button>
      </Modal>
    </Portal>
  );
}
