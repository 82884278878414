import {
  MaterialCommunityIcons,
  MaterialIcons,
  Ionicons,
  AntDesign
} from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { IconButton, Menu, useTheme, Text, Divider } from "react-native-paper";
import { CompositeNavigationProps } from "./types";
import { Platform, View } from "react-native";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import { useTextSize } from "@hooks/useTextSize";
import { useShowAnswers } from "@hooks/useShowAnswers";
import { useShowHeaders } from "@hooks/useShowHeaders";

const PaperScreenButtons = () => {
  const { listen } = useDSSubscriber();
  const { increaseFontSize, decreaseFontSize, getFontSize } = useTextSize();
  const { toggleShowAnswers, getShowAnswers } = useShowAnswers();
  const { toggleShowHeaders, getShowHeaders } = useShowHeaders();
  const [fontSizeState, setFontSizeState] = useState<number>(getFontSize());
  useEffect(() => {
    listen("FontSize", () => setFontSizeState(getFontSize()));
  });

  const theme: ReactNativePaper.Theme = useTheme();
  const navigation: CompositeNavigationProps = useNavigation();
  const [visible, setVisible] = useState(false);
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Menu
        visible={visible}
        onDismiss={() => setVisible(false)}
        anchor={
          <IconButton
            icon={() => (
              <MaterialCommunityIcons
                name='dots-vertical'
                size={24}
                color={theme.colors.white}
              />
            )}
            onPress={() => setVisible(true)}
          />
        }
      >
        <Menu.Item
          onPress={() => {
            setVisible(false);
            navigation.setParams({ reorder: true });
          }}
          leadingIcon={() => (
            <MaterialCommunityIcons
              name='sort-numeric-variant'
              color={theme.colors.primary}
              size={24}
            />
          )}
          title='Reorder'
        />
        <Menu.Item
          onPress={() => {
            setVisible(false);
            navigation.setParams({ confirmRegenerate: true });
          }}
          leadingIcon={() => (
            <MaterialIcons name='loop' color={theme.colors.primary} size={24} />
          )}
          title='Regenerate'
        />
        <Menu.Item
          onPress={() => {
            setVisible(false);
            navigation.setParams({ print: true });
          }}
          leadingIcon={() => (
            <MaterialIcons
              name='print'
              color={theme.colors.primary}
              size={24}
            />
          )}
          title='Print'
        />
        {Platform.OS === "web" ? (
          <Menu.Item
            onPress={() => {
              setVisible(false);
              navigation.setParams({ download: true });
            }}
            leadingIcon={() => (
              <AntDesign
                name='download'
                color={theme.colors.primary}
                size={24}
              />
            )}
            title='Download'
          />
        ) : (
          <Menu.Item
            onPress={() => {
              setVisible(false);
              navigation.setParams({ share: true });
            }}
            leadingIcon={() => (
              <MaterialIcons
                name='share'
                color={theme.colors.primary}
                size={24}
              />
            )}
            title='Share'
          />
        )}
        <Divider style={{ margin: 8 }} />
        <Menu.Item
          onPress={() => {
            setVisible(false);
            toggleShowAnswers();
          }}
          leadingIcon={() => (
            <MaterialIcons
              name='lightbulb'
              color={theme.colors.primary}
              size={24}
            />
          )}
          contentStyle={{ marginHorizontal: 12 }}
          trailingIcon={() =>
            getShowAnswers() && (
              <MaterialCommunityIcons
                name='check'
                color={theme.colors.primary}
                size={24}
              />
            )
          }
          title='Show answers'
        />
        <Menu.Item
          onPress={() => {
            setVisible(false);
            toggleShowHeaders();
          }}
          leadingIcon={() => (
            <Ionicons name='ios-list' color={theme.colors.primary} size={24} />
          )}
          contentStyle={{ marginHorizontal: 12 }}
          trailingIcon={() =>
            getShowHeaders() && (
              <MaterialCommunityIcons
                name='check'
                color={theme.colors.primary}
                size={24}
              />
            )
          }
          title='Show headers'
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <IconButton
            icon={() => (
              <MaterialCommunityIcons
                name='minus'
                color={theme.colors.primary}
                size={24}
              />
            )}
            onPress={decreaseFontSize}
          />
          <Text style={{ fontSize: 16 }}>Font size: {fontSizeState}</Text>
          <IconButton
            icon={() => (
              <MaterialCommunityIcons
                name='plus'
                color={theme.colors.primary}
                size={24}
              />
            )}
            onPress={increaseFontSize}
          />
        </View>
      </Menu>
    </View>
  );
};

export default PaperScreenButtons;
