import { ScrollView, StyleSheet, View } from "react-native";
import { FAB, IconButton, useTheme } from "react-native-paper";
import { MessageType, User } from "../../types";
import ChatInput from "./ChatInput";
import ChatOptions from "./ChatOptions";
import MessageBoard from "./MessageBoard";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useRef, useState } from "react";

interface Props {
  messages: (MessageType.Text | MessageType.Image)[];
  onSendPress: (
    message: MessageType.PartialText | MessageType.PartialImage
  ) => void;
  user: User;
}

export default function Chat({ messages, onSendPress, user }: Props) {
  // use ref to scroll to bottom
  const scrollViewRef = useRef<ScrollView>(null);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const theme: ReactNativePaper.Theme = useTheme();
  const insets = useSafeAreaInsets();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "column",
      height: "100%",
      backgroundColor: theme.colors.textbox,
      // paddingTop: insets.top,
      paddingBottom: insets.bottom,
      paddingLeft: insets.left,
      paddingRight: insets.right
    },
    image: {
      flex: 1,
      justifyContent: "center",
      width: "100%",
      height: "100%"
    },
    scrollview: {
      flex: 1,
      padding: 10,
      position: "relative"
    },
    options: {
      height: 180,
      padding: 10
    },
    textinput: {
      minHeight: 72
    },
    fab: {
      position: "absolute",
      bottom: 0,
      right: 0,
      marginBottom: 72 + 16 + insets.bottom,
      marginRight: 16,
      backgroundColor: theme.colors.white
    }
  });

  const isScrolledToBottom = scrollHeight + scrollOffset >= contentHeight - 1;
  console.log(
    "scrollHeight: " +
      scrollHeight +
      ", scrollOffset: " +
      scrollOffset +
      ", contentHeight: " +
      contentHeight +
      ", isScrolledToBottom: " +
      isScrolledToBottom
  );

  const handleScrollToBottom = () => {
    scrollViewRef.current?.scrollToEnd({ animated: true });
  };

  const handleSendPress = (
    message: MessageType.PartialText | MessageType.PartialImage
  ) => {
    onSendPress(message);
    // scroll to bottom
    handleScrollToBottom();
  };

  const answers = messages[messages.length - 1].metadata?.answers || [];
  return (
    <View style={styles.container}>
      <ScrollView
        style={styles.scrollview}
        ref={scrollViewRef}
        onContentSizeChange={(contentWidth, contentHeight) => {
          setContentHeight(contentHeight);
        }}
        onScroll={event => {
          setScrollOffset(event.nativeEvent.contentOffset.y);
        }}
        onLayout={event => {
          setScrollHeight(event.nativeEvent.layout.height);
        }}
      >
        <MessageBoard messages={messages} user={user} />
      </ScrollView>
      {answers.length > 0 && (
        <View style={styles.options}>
          <ChatOptions onSendPress={handleSendPress} answers={answers} />
        </View>
      )}
      <ChatInput onSendPress={handleSendPress} />
      {/* Show scroll to bottom icon button if it's not scrolled to bottom */}
      {!isScrolledToBottom && (
        <FAB
          icon='chevron-double-down'
          onPress={handleScrollToBottom}
          style={styles.fab}
          color={theme.colors.primary}
        />
      )}
    </View>
  );
}
function userTheme() {
  throw new Error("Function not implemented.");
}
