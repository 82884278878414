import MultipleChoice from "@components/questions/MultipleChoice";
import Others from "@components/questions/Others";
import QuestionOnly from "@components/questions/QuestionOnly";
import QuestionWithLines from "@components/questions/QuestionWithLines";
import ListOfQuestions from "@components/questions/ListOfQuestions";
import ListOfSections from "@components/questions/ListOfSections";
import TrueFalse from "@components/questions/TrueFalse";
import { MarkdownIt } from "react-native-markdown-display";
import { additionalStyles } from "./htmlStyles";
import {
  Paper as PaperSchema,
  Section as SectionSchema,
  Question as QuestionSchema
} from "@synthesizer/common/dist/localDbSchema";
import {
  BeforeInsert,
  BeforeUpdate,
  Entity,
  ManyToOne,
  OneToMany
} from "typeorm";
import {
  PaperStatus as commonPaperStatus,
  PaperLevel as commonPaperLevel,
  PaperSubject as commonPaperSubject,
  SectionType as commonSectionType,
  QuestionType as commonQuestionType
} from "@synthesizer/common/dist/localDbSchema";

export { commonPaperStatus as PaperStatus };
export { commonPaperLevel as PaperLevel };
export { commonPaperSubject as PaperSubject };
export { commonSectionType as SectionType };
export { commonQuestionType as QuestionType };

@Entity()
export class Paper extends PaperSchema {
  @OneToMany(() => Section, section => section.paper)
  sections: Section[];

  toMarkdown(showHeaders: boolean): string {
    const isChinese = this.subject == "Chinese";
    return (
      (showHeaders
        ? `# ${this.title} \n\n` +
          `## ${this.subject} / ${this.level} \n\n` +
          `| ${isChinese ? "姓名" : "Name"}: _______________ | ${
            isChinese ? "日期" : "Date"
          }: _______________ |\n| :------ | ------: | \n\n`
        : null) +
      `${this.sections
        .sort((a, b) => a.index - b.index)
        .map(s => s.toMarkdown())
        .join(`\n`)}`
    );
  }

  toHtml(fontSize: number, showHeaders: boolean): string {
    const markdownPaper = this.toMarkdown(showHeaders);
    const markdownItInstance = MarkdownIt({ typographer: true });
    const html = markdownItInstance.render(
      markdownPaper.replaceAll("\n", "  \n")
    );
    const htmlStyles = `
    * {
      font-size: ${fontSize};
      margin: 8px 0px;
      line-height: 1.4;
    }
    h3 {
      margin-top: 16px;
      font-size: ${fontSize + 2};
      font-weight: "bold";
    }
    ${additionalStyles}`;

    const fullHtml = `
      <html>
        <head>
          <style>
            ${htmlStyles}
          </style>
        </head>
        <body onafterprint="self.close()" >
          ${html}
        </body>
      </html>`;

    return fullHtml;
  }

  toJSX(): JSX.Element {
    return <ListOfSections key={this.id} paper={this} />;
  }
}

@Entity()
export class Section extends SectionSchema {
  @ManyToOne(() => Paper, paper => paper.sections, { onDelete: "CASCADE" })
  paper: Paper;

  @OneToMany(() => Question, question => question.section)
  questions: Question[];

  toMarkdown(): string {
    return `### ${this.title}\n${this.questions
      .sort((a, b) => a.index - b.index)
      .map((q, index) => `${this.questions[index].index}. ${q.toMarkdown()}`)
      .join(`\n\n`)}`;
  }

  toJSX(): JSX.Element {
    return <ListOfQuestions key={this.id} section={this} />;
  }
}

@Entity()
export class Question extends QuestionSchema {
  @BeforeInsert()
  @BeforeUpdate()
  cleanUpData() {
    this.content = this.content.replaceAll("\n", ""); // Remove line breaks in questions
    this.content = this.content.replace(/^[0-9a-eA-E]\.\s*/, ""); // Remove prefixes in questions
    if (this.questionType === commonQuestionType.MultipleChoice) {
      // Remove MC options prefixes
      this.options = this.options?.map(option =>
        option.replace(/^[0-9a-eA-E]\.\s*/, "")
      );
    }
  }

  @ManyToOne(() => Section, section => section.questions, {
    onDelete: "CASCADE"
  })
  section: Section;

  toMarkdown(): string {
    switch (this.questionType.toLowerCase()) {
      case commonQuestionType.ShortAnswer:
      case commonQuestionType.LongAnswer:
      case commonQuestionType.Reorder:
      case commonQuestionType.Essay:
        return `${this.content}\n___`;
      case commonQuestionType.FillInTheBlank:
        return `${this.content}`;
      case commonQuestionType.MultipleChoice:
        return `${this.content}\n${this.options
          ?.map(q => `- ${q}`)
          .join(`\n`)}`;
      case commonQuestionType.TrueFalse:
        return `${this.content}\n- True\n- False`;
      case commonQuestionType.Matching:
      case commonQuestionType.Other:
      default:
        return `${this.content}\n❌ This question type is currently not supported`;
    }
  }

  toJSX(): JSX.Element {
    switch (this.questionType.toLowerCase()) {
      case commonQuestionType.ShortAnswer:
      case commonQuestionType.LongAnswer:
      case commonQuestionType.Reorder:
      case commonQuestionType.Essay:
        return <QuestionWithLines key={this.id} question={this} />;
      case commonQuestionType.FillInTheBlank:
      case commonQuestionType.Math:
        return <QuestionOnly key={this.id} question={this} />;
      case commonQuestionType.MultipleChoice:
        return <MultipleChoice key={this.id} question={this} />;
      case commonQuestionType.TrueFalse:
        return <TrueFalse key={this.id} question={this} />;
      case commonQuestionType.Matching:
      case commonQuestionType.Other:
      default:
        return <Others key={this.id} question={this} />;
    }
  }
}
