import {
  ActivityIndicator,
  Modal,
  Portal,
  Text,
  useTheme
} from "react-native-paper";
import { StyleSheet } from "react-native";
import useIsLargeScreen from "@hooks/useIsLargeScreen";

export default function LoadingModal({ visible }: { visible: boolean }) {
  const theme: ReactNativePaper.Theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const styles = StyleSheet.create({
    modalContainer: {
      backgroundColor: theme.colors.white,
      padding: 20,
      paddingVertical: 40,
      borderRadius: 10,
      gap: 24,
      width: isLargeScreen ? 400 : "90%",
      alignSelf: "center"
    },
    text: {
      textAlign: "center"
    }
  });

  const jokes = [
    "Why did ChatGPT refuse to go on a date? Because it was already too busy processing data!",
    "Why did ChatGPT get lost in the forest? Because it couldn't find the right algorithm!",
    "ChatGPT tried to tell a joke, but the punchline was stuck in the cloud.",
    "I heard the ChatGPT servers are slow because they're trying to teach the AI how to be patient!",
    "The ChatGPT servers must be stuck in traffic... in the internet highway!",
    "Maybe the ChatGPT servers are just taking a break to meditate and clear their electronic minds.",
    "The ChatGPT servers must be stuck in a never-ending game of chess with each other.",
    "I heard the ChatGPT servers are slow because they're trying to teach the AI how to make coffee."
  ];

  const randomJoke = jokes[Math.floor(Math.random() * jokes.length)];

  return (
    <Portal>
      <Modal
        visible={visible}
        contentContainerStyle={styles.modalContainer}
        theme={{ colors: { backdrop: "#ffffff50" } }}
        dismissable={false}
      >
        <ActivityIndicator
          animating={true}
          color={theme.colors.primary}
          size='large'
        />
        <Text style={styles.text}>
          {Math.random() > 0.9
            ? randomJoke
            : "Hang tight while we're generating your content!"}
        </Text>
      </Modal>
    </Portal>
  );
}
