import { ReactNode, createContext } from "react";

export type DSSubscriber = {
  // custom event listener and dispatcher
  listen: (evt: string, callback: (evt: string) => void) => void;
  dispatch: (evt: string) => void;
};

// datasource context
export const DataSourceContext = createContext<DSSubscriber>({
  listen: () => {},
  dispatch: () => {}
});

export default function DataSourceProvider({
  children
}: {
  children?: ReactNode;
}) {
  const listeners: { [key: string]: ((evt: string) => void)[] } = {};

  const dispatch = (evt: string) => {
    if (!listeners[evt]) return;
    listeners[evt].forEach(listener => listener(evt));
  };

  const listen = (evt: string, callback: (evt: string) => void) => {
    if (!listeners[evt]) {
      listeners[evt] = [];
    }
    const cbInsertPosition = listeners[evt].push(callback) - 1;
    const unlisten = () => {
      listeners[evt][cbInsertPosition] = () => {};
    };
    return unlisten;
  };

  return (
    <DataSourceContext.Provider value={{ listen, dispatch }}>
      {children}
    </DataSourceContext.Provider>
  );
}
