export const additionalStyles = `
    hr {
        margin-bottom: 24;
        margin-top: 32;
    }
    table {
        width: 100%;
        margin-bottom: 16;
    }
    h1 {
        text-align: center;
        font-size: 2em;
    }
    h2 {
        text-align: center;
        font-size: 1.5em;
    }
    ol {
        text-align: left;
        margin-left: -24px;
    }
    ul {
        list-style-type: "◯ ";
        text-align: left;
        margin-left: -12px;
        margin-bottom: 16px;
    }
`;
