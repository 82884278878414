import { View } from "react-native";
import { Paper } from "@entities/index";
import DashedLine from "react-native-dashed-line";
import BaseSection from "./BaseSection";

export default function ListOfSections({ paper }: { paper: Paper }) {
  return (
    <>
      {paper.sections.map(section => (
        <View key={section.id}>
          {/* This flexShrink hotfix allows text overflow wrapping  */}
          <View style={{ flexShrink: 1, maxWidth: "100%" }}>
            {/* Renders the section title, passage, instructions etc. */}
            <BaseSection section={section} />
          </View>
          {/* Renders the questions in the corresponding section, which invokes ListOfQuestions.tsx */}
          <View>{section.toJSX()}</View>
          <DashedLine
            dashColor='#aaaaaa'
            dashGap={5}
            style={{ marginVertical: 12 }}
          />
        </View>
      ))}
    </>
  );
}
