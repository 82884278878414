import {
  PaperDifficulty,
  PaperLevel,
  PaperStatus,
  PaperSubject,
  Section
} from "@synthesizer/common/dist/localDbSchema";
import axios from "axios";
import Constants from "expo-constants";

const API_URL = Constants?.expoConfig?.extra?.API_URL;

export const parsePaper = async (imagesBase64: string[]) => {
  try {
    const response = await axios.post(
      `${API_URL}/openai/parse-paper`,
      { imagesBase64 },
      {
        headers: {
          "Content-Type": "Application/json"
        }
      }
    );
    if (response.data.warning) {
      console.log(
        "Warning! Something went wrong during paper parsing.",
        response.data.warning
      );
    }
    return response.data.data as {
      school: string;
      level: PaperLevel;
      title: string;
      subject: PaperSubject;
      difficulty: PaperDifficulty;
      timeAllowed: number;
      status: PaperStatus;
      sections: Section[];
    };
  } catch (e) {
    console.log("error", e);
    return;
  }
};
