import { Button, Switch, Text } from "react-native-paper";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import BaseModal from "@components/questions/BaseModal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Paper, Question, Section } from "@entities/index";
import DraggableFlatList, {
  RenderItemParams
} from "react-native-draggable-flatlist";
import { MaterialIcons } from "@expo/vector-icons";
import Container from "typedi";
import { DataSource } from "typeorm";
import { useDSSubscriber } from "@hooks/useDSSubscriber";

export default function ReorderModal({
  visible,
  setPaperReordering,
  paper
}: {
  visible: boolean;
  setPaperReordering: Dispatch<SetStateAction<boolean>>;
  paper: Paper;
}) {
  // Styles for the screen
  const styles = StyleSheet.create({
    switch: {
      flexDirection: "row",
      gap: 16,
      justifyContent: "center",
      alignItems: "center"
    },
    buttons: {
      flexDirection: "row",
      justifyContent: "center",
      gap: 32
    },
    reorder: {
      maxHeight: 320,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: "#00000030",
      paddingVertical: 8,
      paddingRight: 24
    },
    draggable: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 16,
      paddingVertical: 8
    }
  });

  // Declare the subscriber hook
  const { dispatch } = useDSSubscriber();

  // Data states for the order of sections and questions
  const initialSectionData = paper.sections
    .sort((a, b) => a.index - b.index)
    .map((section, index) => {
      return { key: `sid-${section.id}` as `sid-${string}`, index, section };
    });

  const initialQuestionData = paper.sections
    .sort((a, b) => a.index - b.index) // [section1, section2]
    .map((section, index) => {
      const sectionObject = {
        key: `sid-${section.id}` as `sid-${string}`,
        index,
        section
      };
      return [sectionObject, sectionObject];
    }) // [[section1, section1], [section2, section2]]
    .flat() // [section1, section1, section2, section2]
    .map((section, index) => {
      if (index % 2 === 0) return section;
      return section.section.questions
        .sort((a, b) => a.index - b.index)
        .map((question, index) => {
          return {
            key: `qid-${question.id}` as `qid-${string}`,
            index,
            question
          };
        });
    }) // [section1, [question1, question2], section2, [question1, question2]]
    .flat(); // [section1, question1, question2, section2, question1, question2]

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [sectionData, setSectionData] = useState(initialSectionData);
  const [questionData, setQuestionData] = useState(initialQuestionData);

  // Reset the order of sections and questions upon closing the modal
  useEffect(() => {
    if (!visible) {
      setSectionData(initialSectionData);
      setQuestionData(initialQuestionData);
    }
  }, [visible]);

  // As per documentation at https://www.typescriptlang.org/docs/handbook/advanced-types.html to define a type guard for the union draggable type.
  function isSection(
    draggable: (typeof questionData)[number]
  ): draggable is (typeof sectionData)[number] {
    return (draggable as (typeof sectionData)[number]).section !== undefined;
  }

  // Draggable flat list item renderers (1)
  const renderSection = ({
    item,
    drag,
    isActive
  }: RenderItemParams<(typeof sectionData)[number]>) => {
    return (
      <TouchableOpacity
        onLongPress={drag}
        disabled={isActive}
        activeOpacity={0.8}
      >
        <View
          style={{
            ...styles.draggable,
            backgroundColor: isActive ? "#ff000010" : undefined
          }}
        >
          <MaterialIcons
            name='drag-indicator'
            size={24}
            color='black'
            style={{ marginRight: 12, marginLeft: -4 }}
          />
          <Text style={{ width: "100%" }} numberOfLines={1}>
            {item.section.title}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  // Draggable flat list item renderers (2)
  const renderQuestion = ({
    item,
    drag,
    isActive
  }: RenderItemParams<(typeof questionData)[number]>) => {
    if (isSection(item))
      return (
        <View style={{ ...styles.draggable }}>
          <Text style={{ width: "100%" }} numberOfLines={1}>
            {item.section.title}
          </Text>
        </View>
      );
    return (
      <TouchableOpacity
        onLongPress={drag}
        disabled={isActive}
        activeOpacity={0.8}
      >
        <View
          style={{
            ...styles.draggable,
            backgroundColor: isActive ? "#ff000010" : undefined
          }}
        >
          <MaterialIcons
            name='drag-indicator'
            size={24}
            color='black'
            style={{ marginRight: 12, marginLeft: -4 }}
          />
          <Text style={{ width: "100%" }} numberOfLines={1}>
            {item.question.content}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  // Execute asynchronous DB operations to update the order of sections and questions
  const updateOrder = async () => {
    const datasource = Container.get(DataSource);
    await Promise.allSettled([
      // Update the section indices
      ...sectionData.map(async (data, index) => {
        const section = await datasource
          .getRepository(Section)
          .findOneBy({ id: data.section.id });
        if (!section) return;
        section.index = index + 1;
        return datasource.getRepository(Section).save(section);
      }),
      // Update the question indices and corresponding section they belong to
      ...questionData.map(async (data, index) => {
        if (isSection(data)) return;
        const belongingSection =
          (questionData
            .slice(0, index) // Starting from the current question, find the first section above (before) it.
            .reverse()
            .find(draggable => isSection(draggable)) as
            | (typeof sectionData)[number]
            | undefined) ?? initialSectionData[0]; // If no section is found, then the question belongs to the first section.
        const belongingSectionIndex = questionData.indexOf(belongingSection);
        const question = await datasource
          .getRepository(Question)
          .findOneBy({ id: data.question.id });
        if (!question) return;
        question.index = index - belongingSectionIndex;
        question.section = belongingSection.section;
        return datasource.getRepository(Question).save(question);
      })
    ]);
    dispatch("PaperDetails");
  };

  return (
    <BaseModal
      title='Reorder paper elements'
      visible={visible}
      setVisible={setPaperReordering}
    >
      <View style={styles.switch}>
        <Text>Sections</Text>
        <Switch
          value={isSwitchOn}
          onValueChange={() => setIsSwitchOn(!isSwitchOn)}
        />
        <Text>Questions</Text>
      </View>
      {!isSwitchOn ? (
        <DraggableFlatList
          scrollEnabled={true}
          data={sectionData}
          onDragEnd={({ data }) => setSectionData(data)}
          keyExtractor={item => item.key}
          renderItem={renderSection}
          style={styles.reorder}
        />
      ) : (
        <DraggableFlatList
          scrollEnabled={true}
          data={questionData}
          onDragEnd={({ data }) => {
            let modifiedData = [...data];
            // This avoids questions being dragged above the first section (Invalid move)
            const firstSectionIndex = data.findIndex(draggable =>
              isSection(draggable)
            );
            modifiedData.unshift(modifiedData.splice(firstSectionIndex, 1)[0]); // Move the first section to the top of the list
            // This avoids empty sections after dragging (Invalid move)
            if (
              modifiedData.some((draggable, index) => {
                if (index === 0) return false; // Check whether there exists two consecutive sections without questions in between
                if (isSection(draggable) && isSection(modifiedData[index - 1]))
                  return true;
                return false;
              })
            )
              return; // If so, return without updating the data state.
            setQuestionData(modifiedData);
          }}
          keyExtractor={item => item.key}
          renderItem={renderQuestion}
          style={styles.reorder}
        />
      )}
      <View style={styles.buttons}>
        <Button
          mode='contained'
          onPress={() => {
            updateOrder();
            setPaperReordering(false);
          }}
        >
          Confirm
        </Button>
        <Button
          mode='outlined'
          onPress={() => {
            setPaperReordering(false);
          }}
        >
          Cancel
        </Button>
      </View>
    </BaseModal>
  );
}
