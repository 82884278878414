import { Paper } from "@entities/index";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import { useTextSize } from "@hooks/useTextSize";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import React, { useEffect, useState } from "react";

export default function PaperHeader({ paper }: { paper: Paper }) {
  const { listen } = useDSSubscriber();
  const { getFontSize } = useTextSize();
  const [fontSizeState, setFontSizeState] = useState<number>(getFontSize());
  useEffect(() => {
    listen("FontSize", () => setFontSizeState(getFontSize()));
  });

  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: 24,
      gap: 32
    },
    nameAndDate: {
      alignSelf: "stretch",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      rowGap: 24
    },
    title: {
      textAlign: "center",
      fontSize: fontSizeState + 12
    },
    subtitle: {
      textAlign: "center",
      fontSize: fontSizeState + 4
    },
    text: {
      fontSize: fontSizeState
    }
  });
  return (
    <View style={styles.container}>
      <View style={{ gap: 8 }}>
        <Text style={styles.title}>{paper.title}</Text>
        <Text style={styles.subtitle}>
          {paper.subject} / {paper.level}
        </Text>
      </View>
      <View style={styles.nameAndDate}>
        <Text style={styles.text}>
          {paper.subject == "Chinese" ? "姓名" : "Name"}: _______________
        </Text>
        <Text style={styles.text}>
          {paper.subject == "Chinese" ? "日期" : "Date"}: _______________
        </Text>
      </View>
    </View>
  );
}
