// ScreenList.tsx
import PaperStackNavigator from "@components/navigations/PaperStackNavigator";
import useIsLargeScreen from "@hooks/useIsLargeScreen";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useTheme } from "react-native-paper";
import FilterDrawerContent from "./FilterDrawerContent";
import PaperPermanentDrawer from "./PaperPermanentDrawer";

const Drawer = createDrawerNavigator();

export default function FilterDrawer() {
  const theme: ReactNativePaper.Theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  return (
    <Drawer.Navigator
      screenOptions={() => ({
        headerShown: false,
        swipeEnabled: false,
        drawerType: "front",
        drawerActiveTintColor: theme.colors.white,
        drawerInactiveTintColor: theme.colors.grey,
        drawerActiveBackgroundColor: "#FFFFFF33",
        drawerItemStyle: {
          marginHorizontal: 0,
          borderRadius: 0,
          paddingHorizontal: 4,
          fontFamily: "PoppinsRegular",
          fontSize: 14
        },
        overlayColor: "transparent"
      })}
      drawerContent={FilterDrawerContent}
      initialRouteName='PaperStack'
    >
      <Drawer.Screen
        name='Home'
        component={isLargeScreen ? PaperPermanentDrawer : PaperStackNavigator}
        initialParams={{ labels: "all" }}
        options={{ drawerLabel: "No Paper Found" }}
      />
    </Drawer.Navigator>
  );
}
