import { useMemo, useState } from "react";
import { Text, View } from "react-native";
import { styles } from "./styles";
import BaseQuestion from "./BaseQuestion";
import { useTextSize } from "@hooks/useTextSize";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import { Question } from "@entities/index";

export default function TrueFalse({ question }: { question: Question }) {
  const { listen } = useDSSubscriber();

  const { getFontSize } = useTextSize();
  const [fontSizeState, setFontSizeState] = useState<number>(getFontSize());
  const dynamicStyles = useMemo(() => {
    return {
      fontSize: fontSizeState,
      lineHeight: fontSizeState * 1.6
    };
  }, [fontSizeState]);
  listen("FontSize", () => setFontSizeState(getFontSize()));

  const option = ["True", "False"];

  return (
    <BaseQuestion question={question}>
      {option.map((option, index) => (
        <View key={index} style={styles.mc}>
          <Text style={{ ...styles.unordered_bullet, ...dynamicStyles }}>
            ◯
          </Text>
          <Text style={{ ...styles.question, ...dynamicStyles }}>{option}</Text>
        </View>
      ))}
    </BaseQuestion>
  );
}
