// ChatDrawerContent will have the following in the layout:
// - A button to create a new chat
// - List of drawer item
// Path: components/Layout/ChatDrawerContent.tsx

import PaperSectionListItem from "@components/papers/PaperSectionListItem";
import ScanPaperButton from "@components/papers/CreatePaperButton";
import { Paper, PaperStatus } from "@entities/index";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import Container from "typedi";
import { DataSource } from "typeorm";
import {
  DrawerContentComponentProps,
  DrawerContentScrollView
} from "@react-navigation/drawer";
import { useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Appbar, useTheme } from "react-native-paper";
import { Not } from "typeorm";

const PaperPermanenetDrawerContent = (props: DrawerContentComponentProps) => {
  const theme: ReactNativePaper.Theme = useTheme();
  const [papers, setPapers] = useState<Paper[]>([]);
  const [selectedPaperId, setSelectedPaperId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const { listen } = useDSSubscriber();

  useEffect(() => {
    const fetchPapers = async () => {
      const datasource = Container.get(DataSource);
      const papers =
        (await datasource.getRepository(Paper).find({
          where: { status: Not(PaperStatus.Archived) },
          relations: ["sections.questions"]
        })) ?? [];
      papers.reverse();
      setPapers(papers);
      setLoading(false);
    };
    fetchPapers();
    return listen("Papers", fetchPapers);
  }, []);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.black,
      flex: 1
    },
    appbar: {
      backgroundColor: theme.colors.black,
      height: 48
    },
    menu: {
      color: theme.colors.primary,
      margin: 0,
      padding: 0
    },
    title: {
      color: theme.colors.white,
      fontSize: 16,
      fontWeight: "bold",
      margin: 0,
      padding: 0
    },
    backButton: {
      flexDirection: "row",
      alignItems: "center"
    }
  });

  return (
    <DrawerContentScrollView {...props} style={styles.container}>
      <Appbar.Header style={styles.appbar}>
        <Appbar.Content title='Your Papers' titleStyle={styles.title} />
      </Appbar.Header>
      <ScanPaperButton />
      <View>
        {loading ? (
          <ActivityIndicator />
        ) : (
          papers.map(paper => (
            <PaperSectionListItem
              key={paper.id}
              paper={paper}
              isSelected={selectedPaperId == paper.id}
              setSelected={setSelectedPaperId}
            />
          ))
        )}
      </View>
    </DrawerContentScrollView>
  );
};

export default PaperPermanenetDrawerContent;
