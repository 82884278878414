import { TouchableOpacity, View, Text } from "react-native";
import { useEffect, useState, useMemo, ReactNode } from "react";
import { DataSource } from "typeorm";
import { Question } from "@entities/index";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import { useDoubleClick } from "@hooks/useDoubleClick";
import { useShowAnswers } from "@hooks/useShowAnswers";
import { useTextSize } from "@hooks/useTextSize";
import { styles } from "./styles";
import LoadingModal from "@components/papers/LoadingModal";
import QuestionEditModal from "./QuestionEditModal";
import QuestionDeleteModal from "./QuestionDeleteModal";
import useAxios from "axios-hooks";
import Constants from "expo-constants";
import Container from "typedi";
import MathRenderer from "./MathRenderer";
import { hasLatex } from "@lib/commonChecks";
import { getSubject } from "@lib/commonDbCalls";

export default function BaseQuestion({
  question,
  children
}: {
  question: Question;
  children?: ReactNode;
}) {
  const { showActionSheetWithOptions } = useActionSheet();
  const { getShowAnswers } = useShowAnswers();
  const { getFontSize } = useTextSize();
  const { dispatch, listen } = useDSSubscriber();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [regenerating, setRegenerating] = useState(false);
  const [showAnswer, setShowAnswer] = useState(getShowAnswers());
  const [fontSizeState, setFontSizeState] = useState<number>(getFontSize());

  const dynamicStyles = useMemo(() => {
    return {
      fontSize: fontSizeState,
      lineHeight: fontSizeState * 1.6
    };
  }, [fontSizeState]);
  listen("ShowAnswers", () => setShowAnswer(getShowAnswers()));
  listen("FontSize", () => setFontSizeState(getFontSize()));

  const API_URL = Constants?.expoConfig?.extra?.API_URL;
  const [{ data: _d, loading: _l, error: _e }, getResponse] = useAxios(
    {
      url: `${API_URL}/openai/regenerate-question`,
      method: "POST",
      headers: { "Content-Type": "application/json" }
    },
    { manual: true }
  );

  useEffect(() => {
    if (!regenerating) return;
    (async () => {
      try {
        const regeneratedQuestion = await getResponse({
          data: {
            question,
            subject: await getSubject(question.id)
          }
        });
        console.log("regenerated question", regeneratedQuestion);
        const datasource = Container.get(DataSource);
        question = {
          ...question,
          ...regeneratedQuestion.data.questions
        };
        await datasource.getRepository(Question).save(question);
        dispatch("PaperDetails");
      } catch (error) {
        console.log("error when regenerating question", error);
        alert("Something went wrong, please try re-generating again!");
      } finally {
        setRegenerating(false);
      }
    })();
  }, [regenerating]);

  const showOptions = () => {
    showActionSheetWithOptions(
      {
        options: ["Edit", "Re-generate", "Delete", "Cancel"],
        cancelButtonIndex: 3,
        destructiveButtonIndex: 2
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            setEditing(true);
            break;
          case 1:
            setRegenerating(true);
            break;
          case 2:
            setDeleting(true);
            break;
          case 3:
            // Cancel
            break;
        }
      }
    );
  };

  const solutionExists =
    question.solution !== "" && question.solution !== undefined;
  const solution = solutionExists ? (question.solution as string) : "N/A";

  return (
    <TouchableOpacity
      style={{ paddingVertical: 8, backgroundColor: "white" }}
      onPress={useDoubleClick(showOptions)}
      onLongPress={showOptions}
      activeOpacity={0.4}
    >
      <LoadingModal visible={regenerating} />
      <QuestionEditModal
        question={question}
        visible={editing}
        setVisible={setEditing}
      />
      <QuestionDeleteModal
        question={question}
        visible={deleting}
        setVisible={setDeleting}
      />
      <View style={{ flexDirection: "column" }}>
        {/* The question itself */}
        <View style={styles.question}>
          <Text style={{ ...styles.ordered_bullet, ...dynamicStyles }}>
            {question.index}.
          </Text>
          {hasLatex(question.content) ? (
            <MathRenderer text={question.content} fontSize={fontSizeState} />
          ) : (
            <Text style={{ ...styles.text, ...dynamicStyles }}>
              {question.content}
            </Text>
          )}
        </View>
        {/* Answer display */}
        {showAnswer &&
          (hasLatex(question.content) ? (
            <MathRenderer
              text={solution}
              fontSize={fontSizeState}
              color={styles.answer.color}
            />
          ) : (
            <Text style={{ ...styles.answer, ...dynamicStyles }}>
              {solution}
            </Text>
          ))}
        {/* True false options, MC options, writing lines etc. */}
        {children!}
      </View>
    </TouchableOpacity>
  );
}
