import { SaveFormat, manipulateAsync } from "expo-image-manipulator";

const compressImage = async (uri: string) => {
  const result = await manipulateAsync(
    uri,
    [
      {
        resize: {
          height: 1920
        }
      }
    ],
    {
      compress: 0.2,
      format: SaveFormat.JPEG,
      base64: true
    }
  );

  if (!result) {
    throw new Error("Failed to compress image");
  }

  return result.base64!;
};

export default compressImage;
