import { useDSSubscriber } from "./useDSSubscriber";

let showAnswers = false;

export const useShowAnswers = () => {
  const { dispatch } = useDSSubscriber();

  function setShowAnswers(value: boolean) {
    showAnswers = value;
    dispatch("ShowAnswers");
  }

  function toggleShowAnswers() {
    showAnswers = !showAnswers;
    dispatch("ShowAnswers");
  }

  function getShowAnswers() {
    return showAnswers;
  }

  return { getShowAnswers, toggleShowAnswers, setShowAnswers };
};
