import { StyleSheet, View } from "react-native";
import { Button, Text } from "react-native-paper";
import { Question, Section } from "@entities/index";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import Container from "typedi";
import { DataSource } from "typeorm";
import BaseModal from "./BaseModal";
import { Dispatch, SetStateAction } from "react";

export default function SectionDeleteModal({
  section,
  visible,
  setVisible
}: {
  section: Section;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) {
  const { dispatch } = useDSSubscriber();
  const styles = StyleSheet.create({
    sectionText: {
      textAlign: "center",
      fontSize: 14,
      fontFamily: "PoppinsRegular",
      color: "grey"
    },
    buttonContainer: {
      flexDirection: "row",
      justifyContent: "center",
      gap: 32
    }
  });

  const datasource = Container.get(DataSource);

  const handleDeleteSection = async (id: number) => {
    const sectionRepository = datasource.getRepository(Section);
    const section = await sectionRepository.find({ where: { id } });
    if (!section) {
      console.warn(
        "User tried to delete a section, but it could not be found in DB!"
      );
      return;
    }

    // Delete all questions associated with the section
    const questionRepository = datasource.getRepository(Question);
    const questions = await questionRepository.find({
      where: { section: { id } }
    });
    await Promise.all(
      questions.map(question => questionRepository.remove(question))
    );

    // Delete the section
    await sectionRepository.remove(section);

    dispatch("PaperDetails");
  };

  return (
    <BaseModal
      title='Are you sure you want to delete this section?'
      visible={visible}
      setVisible={setVisible}
    >
      <Text style={styles.sectionText}>{section.title}</Text>
      <View style={styles.buttonContainer}>
        <Button
          mode='contained'
          onPress={async () => {
            await handleDeleteSection(section.id);
            setVisible(false);
          }}
        >
          Delete
        </Button>
        <Button mode='outlined' onPress={() => setVisible(false)}>
          Cancel
        </Button>
      </View>
    </BaseModal>
  );
}
