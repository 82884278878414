import { useEffect, useRef, useState } from "react";

export const useDoubleClick = (callback: any, delay = 300) => {
  const [clickCount, setClickCount] = useState(0);
  const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null);

  const handleClick = () => {
    setClickCount(prevCount => prevCount + 1);
  };

  useEffect(() => {
    if (clickCount === 1) {
      timerRef.current = setTimeout(() => {
        setClickCount(0);
      }, delay);
    } else if (clickCount === 2) {
      callback();
      setClickCount(0);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
  }, [clickCount, callback, delay]);

  return handleClick;
};
