// ScreenList.tsx
import PaperStackNavigator from "@components/navigations/PaperStackNavigator";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useTheme } from "react-native-paper";
import PaperPermanenetDrawerContent from "./PaperPermanentDrawerContent";

const Drawer = createDrawerNavigator();

export default function PaperPermanentDrawer() {
  const theme: ReactNativePaper.Theme = useTheme();
  return (
    <Drawer.Navigator
      screenOptions={() => ({
        headerShown: false,
        drawerType: "permanent",
        drawerActiveTintColor: theme.colors.white,
        drawerInactiveTintColor: theme.colors.grey,
        drawerActiveBackgroundColor: "#FFFFFF33",
        drawerItemStyle: {
          marginHorizontal: 0,
          borderRadius: 0,
          paddingHorizontal: 4,
          fontFamily: "PoppinsRegular",
          fontSize: 14
        },
        overlayColor: "transparent"
      })}
      drawerContent={PaperPermanenetDrawerContent}
    >
      <Drawer.Screen
        name='PaperStack'
        component={PaperStackNavigator}
        initialParams={{ labels: "all" }}
        options={{ drawerLabel: "No Paper Found" }}
      />
    </Drawer.Navigator>
  );
}
