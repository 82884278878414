import { Dispatch, SetStateAction, useState } from "react";
import { Button, HelperText, TextInput } from "react-native-paper";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import Container from "typedi";
import { DataSource } from "typeorm";
import BaseModal from "./BaseModal";
import { Keyboard, View } from "react-native";
import { Section } from "@entities/index";

export default function SectionEditModal({
  section,
  visible,
  setVisible
}: {
  section: Section;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) {
  const { dispatch } = useDSSubscriber();
  const [newSection, setNewSection] = useState<string>(section.title);
  const [newSectionInstruction, setNewSectionInstruction] = useState(section.instructions);
  const [newPassage, setNewPassage] = useState(section.passage);
  const [newChoices, setNewChoices] = useState(section.choices?.join(", "));
  const [emptyError, setEmptyError] = useState(false);

  const handleConfirm = async () => {
    if (newSection === "") {
      setEmptyError(true);
      return;
    }
    const datasource = Container.get(DataSource);
    section.title = newSection;
    section.passage = newPassage;
    section.instructions = newSectionInstruction;
    section.choices = newChoices?.split(", ");
    await datasource.getRepository(Section).save(section);
    dispatch("PaperDetails");
    setVisible(false);
  };

  return (
    <BaseModal title='Edit section' visible={visible} setVisible={setVisible}>
      <TextInput
        mode='outlined'
        label='Section'
        defaultValue={section.title}
        onChangeText={text => setNewSection(text)}
        multiline={true}
        contentStyle={{ padding: 0, marginVertical: 12, verticalAlign: "top" }}
        style={{ height: 60, paddingHorizontal: 0 }}
        blurOnSubmit={true}
        onSubmitEditing={() => Keyboard.dismiss()}
      />
      <TextInput
        mode='outlined'
        label='Instructions'
        defaultValue={section.instructions}
        onChangeText={text => setNewSectionInstruction(text)}
        multiline={true}
        contentStyle={{
          padding: 0,
          marginVertical: 12,
          verticalAlign: "top"
        }}
        style={{ height: 60, paddingHorizontal: 0 }}
        blurOnSubmit={true}
        onSubmitEditing={() => Keyboard.dismiss()}
      />
      {section.choices && (
        <TextInput
          mode='outlined'
          label='Choices'
          value={newChoices}
          onChangeText={text => setNewChoices(text)}
          multiline={true}
          contentStyle={{
            padding: 0,
            marginVertical: 12,
            verticalAlign: "top"
          }}
          style={{ height: 60, paddingHorizontal: 0 }}
          blurOnSubmit={true}
          onSubmitEditing={() => Keyboard.dismiss()}
        />
      )}
      {section.passage && (
        <TextInput
          mode='outlined'
          label='Passage'
          defaultValue={section.passage}
          onChangeText={text => setNewPassage(text)}
          multiline={true}
          contentStyle={{
            padding: 0,
            marginVertical: 12,
            verticalAlign: "top"
          }}
          style={{ height: 80, paddingHorizontal: 0 }}
          blurOnSubmit={true}
          onSubmitEditing={() => Keyboard.dismiss()}
        />
      )}
      <View>
        {emptyError && (
          <HelperText type='error' padding='none'>
            The above fields cannot be empty!
          </HelperText>
        )}
      </View>
      <Button mode='contained' onPress={handleConfirm}>
        Confirm
      </Button>
    </BaseModal>
  );
}
