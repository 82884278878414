import FilterDrawer from "@components/navigations/FilterDrawer";
import DataSourceProvider from "@components/providers/DataSourceProvider";
import { NavigationContainer } from "@react-navigation/native";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as React from "react";
import {
  MD3LightTheme as DefaultTheme,
  Provider as PaperProvider
} from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import Container from "typedi";
import dataSource from "@lib/connectDS";
import { DataSource } from "typeorm";
import { useState } from "react";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { isMobile } from "react-device-detect";
import { LogBox, StatusBar } from "react-native";

SplashScreen.preventAutoHideAsync();

const theme = {
  ...DefaultTheme,
  // Specify custom property in nested object
  colors: {
    ...DefaultTheme.colors,
    primary: "#EA6000",
    textbox: "#FCEEE4",
    icon: "#EA6000",
    secondary: "#A4D4D9",
    text2: "#8D7C7C",
    text3: "#C2C2C2",
    white: "#FFFFFF",
    grey: "#CCCCCC",
    black: "#271313",
    headerBgColor: "#F8F8F8",
    elevation: {
      level0: "transparent",
      level1: "#fff",
      level2: "#fff",
      level3: "#fff",
      level4: "#fff",
      level5: "#fff"
    }
  }
};

export default function App() {
  LogBox.ignoreLogs([
    // https://stackoverflow.com/questions/69538962/new-nativeeventemitter-was-called-with-a-non-null-argument-without-the-requir
    "new NativeEventEmitter()",
    // I'm too lazy to refactor the code Lol
    "Require cycle: entities/index.tsx"
  ]);

  const [fontsLoaded] = useFonts({
    PoppinsSemiBold: require("./assets/fonts/Poppins-SemiBold.ttf"),
    PoppinsRegular: require("./assets/fonts/Poppins-Regular.ttf"),
    PoppinsMedium: require("./assets/fonts/Poppins-Medium.ttf"),
    PoppinsLight: require("./assets/fonts/Poppins-Light.ttf")
  });

  const onLayoutRootView = React.useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  let globalDS;
  const [ready, setReady] = useState(false);

  (async () => {
    if (!globalDS) {
      globalDS = await dataSource;
      Container.set(DataSource, globalDS);
      setReady(true);
    }
  })();

  if (!ready || !fontsLoaded) {
    return null;
  }

  if (isMobile) {
    alert(
      "For mobile users, please consider using our app instead of website." +
        "\n\n" +
        "Otherwise, stability is not guaranteed."
    );
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <DataSourceProvider>
        <ActionSheetProvider>
          <SafeAreaProvider>
            <PaperProvider theme={theme}>
              <NavigationContainer onReady={onLayoutRootView}>
                <StatusBar barStyle={"light-content"} />
                <FilterDrawer />
              </NavigationContainer>
            </PaperProvider>
          </SafeAreaProvider>
        </ActionSheetProvider>
      </DataSourceProvider>
    </GestureHandlerRootView>
  );
}
