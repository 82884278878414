import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Button, IconButton, TextInput, useTheme } from "react-native-paper";
import { MessageType } from "../../types";

interface Props {
  onSendPress: (
    message: MessageType.PartialText | MessageType.PartialImage
  ) => void;
  answers: string[];
}

export default function ChatOptions({ onSendPress, answers }: Props) {
  const theme: ReactNativePaper.Theme = useTheme();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "column",
      height: "40%",
      padding: 10
    },
    button: {
      padding: 0,
      margin: 4
    }
  });

  const handleSendPress = (text: string) => {
    const t: MessageType.PartialText | MessageType.PartialImage = {
      text,
      type: "text"
    };
    onSendPress(t);
  };

  return (
    <ScrollView style={styles.container}>
      {answers.map(answer => {
        // return button for answer options
        return (
          <Button
            key={answer}
            mode='contained'
            style={styles.button}
            textColor={theme.colors.black}
            buttonColor={theme.colors.white}
            onPress={() => handleSendPress(answer)}
          >
            {answer}
          </Button>
        );
      })}
    </ScrollView>
  );
}
