import PdfThumbnail from "react-native-pdf-thumbnail";
import { getDocumentAsync } from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";

export const selectImages = async () => {
  const result: ImagePicker.ImagePickerResult =
    await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      quality: 0,
      allowsMultipleSelection: true,
      base64: true
    });

  if (result.canceled) return;
  return result.assets.map(asset => asset.uri);
};

export const selectPdf = async () => {
  // Requires further testing on how "thumbnail" resolutions affect OCR performance, especially for documents with smaller font sizes.
  // Most of them seem to be around 800x600px which is quite low.
  const { assets: pdfFiles } = await getDocumentAsync({
    type: "application/pdf"
  });
  if (!pdfFiles) return;
  const pdfFile = pdfFiles[0]; // Since we're only allowing single file upload
  const { uri: pdfUri } = pdfFile;
  const thumbnails = await PdfThumbnail.generateAllPages(pdfUri);
  const thumbnailUris = thumbnails.map(thumbnail => thumbnail.uri);
  return thumbnailUris;
};
