import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  Image,
  Linking,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle
} from "react-native";
import { Button, Text, useTheme } from "react-native-paper";
import { MessageType } from "../../types";
import MessageTextFormatter from "./MessageTextFormatter";

interface Props {
  message: MessageType.Text | MessageType.Image;
  imageMessage?: MessageType.Image;
  isMe: boolean;
  imageUri?: string;
}

const styles = {
  container: {
    marginBottom: 4
  },
  dialogContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    maxWidth: "100%"
  },
  messageContainer: {
    flexDirection: "column",
    maxWidth: "100%",
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderRadius: 14,
    alignItems: "center"
  },
  arrowDown: {
    width: 0,
    height: 0,
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderTopWidth: 8,
    borderStyle: "solid",
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: "transparent"
  },
  message: {
    fontSize: 14,
    fontFamily: "PoppinsRegular",
    maxWidth: "100%",
    paddingHorizontal: 2
  },
  date: {
    fontFamily: "PoppinsRegular",
    fontSize: 12,
    marginTop: 4,
    marginLeft: 24,
    marginRight: 24
  },
  hr: {
    borderBottomColor: "#E9E9E9",
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginBottom: 16
  },
  productName: {
    fontFamily: "PoppinsSemibold",
    fontSize: 15,
    color: "#000000"
  },
  productPrice: {
    fontFamily: "PoppinsSemibold",
    fontSize: 15
  }
};

export default function Message({ message, isMe }: Props) {
  const theme: ReactNativePaper.Theme = useTheme();

  const openLink = url => {
    Linking.openURL(url);
  };

  const isWeb = Platform.OS === "web";

  const maxWidth = 300;
  const maxHeight = 300;

  return isMe ? (
    <View
      style={
        {
          ...styles.container,
          alignItems: "flex-end",
          marginLeft: 64
        } as StyleProp<ViewStyle>
      }
    >
      <View style={styles.dialogContainer as StyleProp<ViewStyle>}>
        <View
          style={
            {
              ...styles.messageContainer,
              borderTopRightRadius: 0,
              backgroundColor: theme.colors.primary,
              justifyContent: "center",
              alignItems: "center",
              padding: 16
            } as StyleProp<ViewStyle>
          }
        >
          {message.type == "image"
            ? (() => {
                const aspectRatio = message.width / message.height;

                let imgWidth = maxWidth;
                let imgHeight = maxWidth / aspectRatio;

                if (imgHeight > maxHeight) {
                  imgHeight = maxHeight;
                  imgWidth = maxHeight * aspectRatio;
                }

                return (
                  <Image
                    source={{
                      uri: message.uri
                    }}
                    style={{
                      marginBottom: -18,
                      width: imgWidth,
                      height: imgHeight,
                      borderRadius: 14
                    }}
                  />
                );
              })()
            : ""}
          <MessageTextFormatter
            style={{ ...styles.message, color: theme.colors.white }}
            color='white'
          >
            {message.type === "text" ? message.text : ""}
          </MessageTextFormatter>
        </View>
        <View
          style={
            {
              ...styles.arrowDown,
              marginLeft: -8,
              borderTopColor: theme.colors.primary
            } as StyleProp<ViewStyle>
          }
        ></View>
      </View>
      <Text style={{ ...styles.date, color: theme.colors.text3 }}>
        {dayjs().format("H:mm A")}
      </Text>
    </View>
  ) : (
    <View style={{ flexDirection: "row" }}>
      <Image
        source={require("../../assets/icon.png")}
        style={{
          width: 40,
          height: 40,
          borderRadius: 16,
          marginLeft: 16,
          marginTop: 8
        }}
      />
      <View
        style={
          {
            flex: 1,
            flexDirection: "column",
            alignItems: "flex-start",
            marginRight: 64
          } as StyleProp<ViewStyle>
        }
      >
        <View style={{ ...styles.dialogContainer } as StyleProp<ViewStyle>}>
          <View
            style={
              {
                ...styles.arrowDown,
                marginRight: -8,
                borderTopColor: theme.colors.white
              } as StyleProp<ViewStyle>
            }
          ></View>
          <View
            style={
              {
                ...styles.messageContainer,
                borderTopLeftRadius: 0,
                backgroundColor: theme.colors.white
              } as StyleProp<ViewStyle>
            }
          >
            <MessageTextFormatter
              style={{ ...styles.message, color: theme.colors.black }}
              color='black'
            >
              {message.type === "text" ? message.text : ""}
            </MessageTextFormatter>

            {message.metadata?.product && (
              <View>
                <View style={styles.hr} />
                <Text style={styles.productName}>
                  {message.metadata.product.name}
                </Text>
                <Text
                  style={{
                    ...styles.productPrice,
                    color: theme.colors.primary
                  }}
                >
                  {message.metadata.product.price}
                </Text>
                <Button
                  mode='contained'
                  textColor={theme.colors.white}
                  buttonColor={theme.colors.primary}
                  onPress={() => openLink(message.metadata.product.link)}
                  style={{ margin: 16 }}
                >
                  View Detail
                </Button>
              </View>
            )}
          </View>
        </View>
        <Text style={{ ...styles.date, color: theme.colors.text3 }}>
          {dayjs().format("H:mm A")}
        </Text>
      </View>
    </View>
  );
}
