// ChatDrawerContent will have the following in the layout:
// - A button to create a new chat
// - List of drawer item
// Path: components/Layout/ChatDrawerContent.tsx

import { DrawerContentScrollView } from "@react-navigation/drawer";
import { StyleSheet } from "react-native";
import { Text, useTheme } from "react-native-paper";

const DrawerContent = (props: any) => {
  const theme: ReactNativePaper.Theme = useTheme();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.black
    },
    backButton: {
      flexDirection: "row",
      alignItems: "center"
    }
  });

  return (
    <DrawerContentScrollView {...props} style={styles.container}>
      <Text>Filter</Text>
    </DrawerContentScrollView>
  );
};

export default DrawerContent;
