import InputModal from "@components/papers/InputModal";
import LoadingModal from "@components/papers/LoadingModal";
import { Paper, PaperLevel, PaperSubject } from "@entities/index";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import waitUntil from "@lib/waitUntil";
import { useRef, useState } from "react";
import {
  Alert,
  PermissionsAndroid,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View
} from "react-native";
import theme from "react-native-elements/dist/config/theme";
import { Text } from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import {
  createPaper as createDbPaper,
  createSection
} from "@lib/commonDbCalls";
import { parsePaper } from "@lib/commonApiCalls";
import compressImage from "@lib/compressImage";
import { scanDocument } from "@lib/scanDocument";
import { selectPdf, selectImages } from "@lib/selectFiles";
import { MenuView } from "@react-native-menu/menu";
import { Section } from "@entities/index";
import { extractFulfilledPromises } from "@synthesizer/common/dist/promiseQueues";

const styles = StyleSheet.create({
  scanPaperButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF33",
    borderRadius: 8,
    margin: 16,
    paddingVertical: 8
  },
  text: {
    color: theme.colors.white,
    fontFamily: "PoppinsSemiBold",
    fontSize: 14
  }
});

type PaperDetails = {
  title: string;
  subject: PaperSubject;
  level: PaperLevel;
};

const ScanPaperButton = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paperDetails, setPaperDetails] = useState<PaperDetails>({
    title: "",
    subject: PaperSubject.Others,
    level: PaperLevel.Primary1
  });
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { dispatch } = useDSSubscriber();

  // Hot fix for https://stackoverflow.com/questions/68097901/async-function-in-react-doesnt-react-to-state-change.
  // Notes, you can keep using states for JSX elements but for JS code you need to use refs.
  const paperDetailsRef = useRef<PaperDetails>();
  paperDetailsRef.current = paperDetails;
  const modalVisibleRef = useRef<boolean>();
  modalVisibleRef.current = modalVisible;

  // The handler for the scan paper button.
  const createPaper = async ({
    mode
  }: {
    mode: "selectImage" | "selectPdf" | "scanDocument";
  }) => {
    let images: string[] | undefined;
    // prompt user to accept camera permission request if they haven't already
    if (
      Platform.OS === "android" &&
      (await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.CAMERA
      )) !== PermissionsAndroid.RESULTS.GRANTED
    ) {
      Alert.alert(
        "Error",
        "User must grant camera permissions to use document scanner."
      );
      return;
    }

    // TODO: Add support for PDF files on the web.
    // Start scanner or camera or image picker or whatever
    switch (mode) {
      case "scanDocument":
        images = await scanDocument();
        break;
      case "selectImage":
        images = await selectImages();
        break;
      case "selectPdf":
        images = await selectPdf();
        break;
    }

    if (!images) return;

    // after we get the image, we start calling apis
    setLoading(true);
    const compressedImageQueue = await Promise.allSettled(
      images.map(async (imgPath: string) => {
        // compress the image so that it's smaller and faster to upload
        return await compressImage(imgPath);
      })
    );

    // check whether the compressions were successful.
    const compressedImages = extractFulfilledPromises(compressedImageQueue);
    console.log("compressedImageQueue", compressedImageQueue);
    console.log("compressedImages", compressedImages);
    if (compressedImageQueue.length !== compressedImages.length) {
      alert(
        "Something has went wrong during paper analysis! Please try again."
      );
      setLoading(false);
      return;
    }

    // 3rd API call: GPT Format Text
    // Call parsePaper first to get basic details of the paper.
    const parsedPaper = await parsePaper(compressedImages).catch(e =>
      console.warn(e)
    );

    if (!parsedPaper) {
      alert(
        "Something has went wrong during paper analysis! Please try again."
      );
      setLoading(false);
      return;
    }

    // Prompt user to input paper details
    const { title, subject, level, difficulty, school, timeAllowed, status } =
      parsedPaper;
    setPaperDetails({ title, subject, level });

    // setModalVisible(true) is a bit delayed.
    setModalVisible(true);

    await waitUntil(() => modalVisibleRef.current === true);
    // Hold paper creation until the user input is ready.
    await waitUntil(() => modalVisibleRef.current === false);

    // Make DB calls to create the paper
    const newPaper: Paper = await createDbPaper({
      title: paperDetailsRef.current!.title,
      subject: paperDetailsRef.current!.subject,
      level: paperDetailsRef.current!.level,
      difficulty,
      school,
      timeAllowed,
      status
    });
    console.log("newPaper", newPaper);

    // Make DB calls to create the sections and questions.
    (parsedPaper.sections as Section[]).forEach(
      async (section: Section, index: number) => {
        const {
          title,
          sectionType,
          passage,
          choices,
          instructions,
          questions
        } = section;
        await createSection({
          title,
          sectionIndex: index + 1,
          sectionType,
          passage,
          choices,
          instructions,
          paper: newPaper,
          questions
        });
        console.log("Details of the section", section);
      }
    );

    // Finish loading and refresh the papers list.
    setLoading(false);
    dispatch("Papers");
  };

  // The raw UI for the scan paper button.
  const coreButton = [
    <View key='0'>
      <View style={styles.scanPaperButton}>
        <Icon
          name='camera-plus'
          size={18}
          color={theme.colors.white}
          style={{ margin: 4 }}
        />
        <Text style={styles.text}>Create Paper</Text>
      </View>
      <LoadingModal visible={loading} />
      <InputModal
        // See PaperScreen.tsx for more details regarding the key.
        key={JSON.stringify(paperDetails)}
        visible={modalVisible}
        setPaperDetails={setPaperDetails}
        paperDetails={paperDetails}
        setModalVisible={setModalVisible}
      />
    </View>
  ];

  // If we're on web, default to the image picker.
  if (Platform.OS === "web")
    return (
      <TouchableOpacity onPress={() => createPaper({ mode: "selectImage" })}>
        {coreButton}
      </TouchableOpacity>
    );

  // For mobile users, we let them choose between document scanner and PDF picker.
  return (
    <MenuView
      title='Select an option'
      onPressAction={({ nativeEvent }) => {
        switch (nativeEvent.event) {
          case "scan":
            createPaper({ mode: "scanDocument" });
            break;
          case "upload":
            createPaper({ mode: "selectPdf" });
            break;
        }
      }}
      actions={[
        {
          id: "scan",
          title: "Scan document"
        },
        {
          id: "upload",
          title: "Upload PDF"
        }
      ]}
      shouldOpenOnLongPress={false}
    >
      {coreButton}
    </MenuView>
  );
};

export default ScanPaperButton;
