import { useMemo, useState } from "react";
import { Text } from "react-native";
import { styles } from "./styles";
import BaseQuestion from "./BaseQuestion";
import { useTextSize } from "@hooks/useTextSize";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import { Question } from "@entities/index";

export default function Others({ question }: { question: Question }) {
  const { listen } = useDSSubscriber();

  const { getFontSize } = useTextSize();
  const [fontSizeState, setFontSizeState] = useState<number>(getFontSize());
  const dynamicStyles = useMemo(() => {
    return {
      fontSize: fontSizeState,
      lineHeight: fontSizeState * 1.6
    };
  }, [fontSizeState]);
  listen("FontSize", () => setFontSizeState(getFontSize()));

  return (
    <BaseQuestion question={question}>
      <Text style={{ ...styles.text, ...dynamicStyles }}>
        ❌ This question type is currently not supported
      </Text>
    </BaseQuestion>
  );
}
