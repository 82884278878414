import { printAsync } from "expo-print";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

// create a hook to print the message
export const usePrint = () => {
  const [html, setHtml] = useState<string>("");

  const print = (html: string) => {
    setHtml(html);
  };

  useEffect(() => {
    if (html.length > 0) {
      if (Platform.OS === "web") {
        const pW = window.open("", "", "status=1, width=800, height=600")!;
        pW.document.write(html);
        pW.document.close();
        pW.print();
      } else {
        printAsync({
          html,
          height: 842,
          width: 595,
          orientation: "portrait",
          margins: {
            top: 36,
            bottom: 36,
            left: 36,
            right: 36
          }
        });
      }
      setHtml("");
    }
  }, [html]);

  return { html, print };
};
