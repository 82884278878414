import {
  Paper,
  PaperLevel,
  PaperStatus,
  PaperSubject,
  Question,
  Section
} from "@entities/index";
import Container from "typedi";
import { DataSource } from "typeorm";
import { PaperDifficulty } from "@synthesizer/common/dist/localDbSchema";

export const createPaper = async ({
  title,
  status,
  subject,
  level,
  school,
  difficulty,
  timeAllowed
}: {
  title: string;
  status: PaperStatus;
  subject: PaperSubject;
  level: PaperLevel;
  school: string;
  difficulty: PaperDifficulty;
  timeAllowed: number;
}) => {
  const newPaper = new Paper();
  newPaper.title = title;
  newPaper.status = status;
  newPaper.subject = subject;
  newPaper.level = level;
  newPaper.difficulty = difficulty;
  newPaper.timeAllowed = timeAllowed;
  newPaper.school = school;
  const datasource = Container.get(DataSource);
  await datasource.getRepository(Paper).save(newPaper);
  return newPaper;
};

export const createSection = async ({
  title,
  sectionIndex,
  passage,
  sectionType,
  choices,
  instructions,
  paper,
  questions,
  ...kwargs
}: {
  title: string;
  sectionIndex: number;
  sectionType: string;
  passage?: string;
  choices?: string[];
  instructions?: string;
  paper: Paper;
  questions: Question[];
}) => {
  const newSection = new Section();
  newSection.index = sectionIndex;
  newSection.title = title;
  newSection.instructions = instructions;
  newSection.passage = passage;
  newSection.sectionType = sectionType;
  newSection.choices = choices;
  newSection.paper = paper;
  const datasource = Container.get(DataSource);
  await datasource.getRepository(Section).save(newSection);
  const createAllQuestion = questions.forEach(
    async (question, questionIndex) => {
      const newQuestion = new Question();
      newQuestion.index = questionIndex + 1;
      newQuestion.content = question.content;
      newQuestion.questionType = question.questionType;
      newQuestion.options = question.options;
      newQuestion.solution = question.solution;
      newQuestion.section = newSection;
      await datasource.getRepository(Question).save(newQuestion);
      console.log("newQuestion", newQuestion);
    }
  );
  return createAllQuestion;
};

export const getSubject = async (questionId: number) => {
  const datasource = Container.get(DataSource);
  const question = await datasource.getRepository(Question).findOne({
    where: {
      id: questionId
    },
    relations: ["section.paper"]
  });
  if (!question) {
    console.error(
      `Failed to get subject - Question ID ${questionId} not found!`
    );
    return;
  }
  console.log(`Retrieved the paper subject for question with ID ${questionId}`); // Keep this logging to avoid N+1 queries
  return question.section.paper.subject;
};

export const getSection = async (questionId: number) => {
  const datasource = Container.get(DataSource);
  const question = await datasource.getRepository(Question).findOne({
    where: {
      id: questionId
    },
    relations: ["section"]
  });
  if (!question) {
    console.error(
      `Failed to get section - Question ID ${questionId} not found!`
    );
    return;
  }
  console.log(`Retrieved the section for question with ID ${questionId}`); // Keep this logging to avoid N+1 queries
  return question.section;
};
