import { TouchableOpacity, View, Text } from "react-native";
import { useState, useMemo } from "react";
import { Section } from "@entities/index";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { useDoubleClick } from "@hooks/useDoubleClick";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import { useTextSize } from "@hooks/useTextSize";
import { styles } from "./styles";
import SectionEditModal from "./SectionEditModal";
import SectionDeleteModal from "./SectionDeleteModal";

export default function BaseSection({ section }: { section: Section }) {
  const { title, passage, choices, instructions } = section;
  const { showActionSheetWithOptions } = useActionSheet();
  const { listen } = useDSSubscriber();
  const { getFontSize } = useTextSize();
  const [sectionEditing, setSectionEditing] = useState(false);
  const [sectionDeleting, setSectionDeleting] = useState(false);
  const [fontSizeState, setFontSizeState] = useState<number>(getFontSize());

  const dynamicStyles = useMemo(() => {
    return {
      fontSize: fontSizeState + 2,
      lineHeight: fontSizeState * 1.6
    };
  }, [fontSizeState]);
  listen("FontSize", () => setFontSizeState(getFontSize()));

  const handleDoubleClick = () => {
    showOptions();
  };
  const handleClick = useDoubleClick(handleDoubleClick);

  const showOptions = () => {
    showActionSheetWithOptions(
      {
        options: ["Edit", "Delete", "Cancel"],
        cancelButtonIndex: 2,
        destructiveButtonIndex: 1
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            setSectionEditing(true);
            break;
          case 1:
            setSectionDeleting(true);
            break;
          case 2:
            // Cancel
            break;
        }
      }
    );
  };

  return (
    <TouchableOpacity
      style={{ paddingVertical: 8, backgroundColor: "#fff" }}
      onPress={handleClick}
      onLongPress={showOptions}
      activeOpacity={0.5}
    >
      <SectionEditModal
        section={section}
        visible={sectionEditing}
        setVisible={setSectionEditing}
      />
      <SectionDeleteModal
        section={section}
        visible={sectionDeleting}
        setVisible={setSectionDeleting}
      />
      <Text style={{ ...styles.title, ...dynamicStyles }}>{title}</Text>
      {instructions != undefined && instructions != "N/A" ? (
        <Text
          style={{
            ...styles.instructions,
            ...dynamicStyles,
            fontSize: fontSizeState
          }}
        >
          {instructions}
        </Text>
      ) : null}
      {passage != undefined && passage != "N/A" && passage != "" ? (
        <View style={styles.passage}>
          <Text style={{ ...dynamicStyles, fontSize: fontSizeState }}>
            {passage}
          </Text>
        </View>
      ) : null}
      {choices != undefined && choices.length != 0 ? (
        <View style={{ ...styles.choices }}>
          {choices.map((choice, index) => (
            <Text
              style={{ ...dynamicStyles, fontSize: fontSizeState }}
              key={index}
            >
              {choice}
            </Text>
          ))}
        </View>
      ) : null}
    </TouchableOpacity>
  );
}
