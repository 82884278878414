import { Divider } from "react-native-paper";
import { styles } from "./styles";
import BaseQuestion from "./BaseQuestion";
import { Question } from "@entities/index";

export default function QuestionWithLines({
  question
}: {
  question: Question;
}) {
  return (
    <BaseQuestion question={question}>
      <Divider style={styles.line} />
    </BaseQuestion>
  );
}
