// Forked from https://stackoverflow.com/questions/22125865/how-to-wait-until-a-predicate-condition-becomes-true-in-javascript
// Honestly no idea how this works, but it does. Not very TS friendly though.
// @ts-nocheck

export default function waitUntil(conditionFunction: () => boolean) {
  const poll = (resolve: () => void) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 400);
  };

  return new Promise(poll);
}
