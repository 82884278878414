import { useDSSubscriber } from "@hooks/useDSSubscriber";
import Constants from "expo-constants";
import useAxios from "axios-hooks";
import { Paper, PaperStatus } from "@entities/index";
import { createPaper, createSection } from "@lib/commonDbCalls";
import { Section } from "@synthesizer/common/dist/localDbSchema";

// create a hook to regenerate the paper
export const useRegenerate = () => {
  const { dispatch } = useDSSubscriber();
  const API_URL = Constants?.expoConfig?.extra?.API_URL;
  const [{ data: _d, loading: _l, error: _e }, getResponse] =
    useAxios<RegeneratePaperResponse>(
      {
        url: `${API_URL}/openai/regenerate-parallel`,
        method: "POST",
        headers: { "Content-Type": "application/json" }
      },
      { manual: true }
    );

  type RegeneratePaperResponse = {
    data: Paper;
  };

  const regenerate = (
    paper: Paper,
    additionalPrompt: string,
    retainAnswer: boolean,
    retainQuestionType: boolean,
    callback: Function
  ) => {
    if (!paper) return;
    console.log("regenerating");
    (async () => {
      try {
        console.log("retain answer?", retainAnswer);
        console.log("retain question type?", retainQuestionType);
        console.log("addtional prompt:", additionalPrompt);
        const regeneratedPaper = await getResponse({
          data: { paper, additionalPrompt, retainAnswer, retainQuestionType }
        });
        if (!regeneratedPaper) throw new Error("No data from server... Why?");
        console.log("regenerated paper", regeneratedPaper);
        const newPaper: Paper = await createPaper({
          title: paper.title + " (RGN)",
          status: PaperStatus.New,
          subject: paper.subject,
          level: paper.level,
          school: paper.school!,
          difficulty: paper.difficulty,
          timeAllowed: paper.timeAllowed!
        });
        console.log("newPaper", newPaper);
        const createAllQuestion = (sections: Section[]) =>
          sections.forEach(async (section: Section, index: number) => {
            const createSectionResponse = await createSection({
              title: section.title,
              // @ts-ignore
              questions: section.questions,
              paper: newPaper,
              sectionType: section.sectionType,
              sectionIndex: index + 1,
              passage: section.passage,
              instructions: section.instructions,
            });
            console.log("createSectionResponse", createSectionResponse);
          });
        createAllQuestion(regeneratedPaper.data.data.sections);
        callback(); // Callback to close the modal and return to the paper list screen.
        dispatch("Papers");
      } catch (error) {
        console.log("error when regenerating paper", error);
        callback();
        alert("Something went wrong, please try re-generating again!");
      }
    })();
  };

  return { regenerate };
};
