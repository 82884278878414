import DocumentScanner, {
  ResponseType
} from "react-native-document-scanner-plugin";

export const scanDocument = async () => {
  const { scannedImages } = await DocumentScanner.scanDocument({
    responseType: ResponseType.ImageFilePath
  });
  if (!scannedImages || scannedImages.length === 0) return;
  console.log("scannedImages", scannedImages);
  return scannedImages;
};
