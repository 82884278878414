import * as Speech from "expo-speech";
import React, { useEffect, useState } from "react";
import { IconButton, useTheme } from "react-native-paper";
import { MessageType } from "../types";
import Chat from "../components/chats/Chat";
import Constants from "expo-constants";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "@components/navigations/types";

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = Math.floor(Math.random() * 16);
    const v = c === "x" ? r : (r % 4) + 8;
    return v.toString(16);
  });
};
const user = { id: "06c33e8b-e835-4736-80f4-63f44b66666c" };
const bot = { id: "06c33e8b-e835-4736-80f4-63f44b66666d" };

type ChatScreenProps = StackScreenProps<RootStackParamList, "ChatScreen">;

function ChatScreen({ route, navigation }: ChatScreenProps) {
  const API_URL = Constants?.expoConfig?.extra?.API_URL;
  const theme: ReactNativePaper.Theme = useTheme();
  const [messages, setMessages] = useState<
    (MessageType.Text | MessageType.Image)[]
  >([
    {
      author: bot,
      createdAt: Date.now(),
      id: uuidv4(),
      text: `Hi, I'm youCodia Math Tutor. This is your queston:

      ${route.params.question}

      What difficulty you have with this question?`,
      type: "text"
    }
  ]);
  // toggle can speak
  const [canSpeak, setCanSpeak] = useState<boolean>(false);
  // time interval to show loading animation
  const [loadingAnimation, setLoadingAnimation] = useState<NodeJS.Timeout>();
  // status of fetching GPT-3
  const [fetchingGPT, setFetchingGPT] = useState<boolean>(false);

  const { id, title, question, grade } = route.params;

  // add a button to toggle can speak in the right corner of the screen
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <IconButton
          icon={canSpeak ? "volume-high" : "volume-off"}
          iconColor={theme.colors.icon}
          onPress={() => setCanSpeak(!canSpeak)}
        />
      )
    });
  }, [canSpeak]);

  // when fetchingGPT, show loading animation and speak "..."
  useEffect(() => {
    if (fetchingGPT) {
      const gptLoadingMessage: MessageType.Text = {
        author: bot,
        createdAt: Date.now(),
        id: uuidv4(),
        text: "...",
        type: "text"
      };
      setLoadingAnimation(
        setInterval(() => {
          addMessage([gptLoadingMessage]);
          gptLoadingMessage.text += ".";
        }, 1000)
      );
    } else {
      clearInterval(loadingAnimation);
    }
  }, [fetchingGPT]);

  const speak = (message: string) => {
    // filter the message excluded brackets and content inside, e.g. [link](https://www.google.com) -> link.
    // filter the emoji text, e.g. 😂 -> ""
    // Speak the message in Cantonese and English when can speak is true
    if (!canSpeak) return;
    message = message.replace(/\[(.*?)\]\(.*?\)/g, "$1");
    message = message.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
    Speech.speak(message, { language: "zh-HK" });
  };

  const addMessage = (message: (MessageType.Text | MessageType.Image)[]) => {
    setMessages([...messages, ...message]);
  };
  const getMessageHistory = () => {
    if (messages.length == 0) {
      return [];
    }
    return messages
      .slice(-12)
      .map(message => {
        const messageObj = {
          role: message.author.id == bot.id ? "assistant" : "user",
          content: message.type == "text" ? message.text.trim() : ""
        };
        return messageObj;
      })
      .filter(message => message.content.length > 0);
  };

  const handleSendPress = async (
    message: MessageType.PartialText | MessageType.PartialImage
  ) => {
    const processedMessage: MessageType.Text | MessageType.Image =
      message.type == "text"
        ? {
            author: user,
            createdAt: Date.now(),
            id: uuidv4(),
            text: message.text,
            type: "text"
          }
        : {
            id: uuidv4(),
            author: user,
            createdAt: Date.now(),
            text: message.text,
            ...message
          };
    addMessage([processedMessage]);
    console.log("lam get message:" + getMessageHistory().toString());
    console.log(
      " lam processedMessage",
      JSON.stringify({
        prompt: processedMessage.text,
        context: getMessageHistory(),
        id,
        title,
        question,
        grade
      })
    );
    try {
      setFetchingGPT(true);
      const answerResponse = await fetch(`${API_URL}/chat/response`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          prompt: processedMessage.text,
          context: getMessageHistory(),
          id,
          title,
          question,
          grade
        })
      });
      setFetchingGPT(false);
      const answerJSON = await answerResponse.json();
      console.log("answerJSON", answerJSON);

      // clearInterval(loadingAnimation);
      const newMessage: MessageType.Text | MessageType.Image = {
        author: bot,
        createdAt: Date.now(),
        id: uuidv4(),
        text: "...",
        type: "text"
      };
      newMessage.text = answerJSON.data.trim();
      if (answerJSON.answers && answerJSON.answers.length > 0) {
        newMessage.metadata = { answers: answerJSON.answers };
      }
      addMessage([processedMessage, newMessage]);
      speak(newMessage.text);
    } catch (err) {
      console.log("an error has occurred while fetching gpt", err);
      setFetchingGPT(false);
    }
  };

  return <Chat messages={messages} onSendPress={handleSendPress} user={user} />;
}

export default ChatScreen;
