import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";

// a hook to determine if the screen is large or not
const useIsLargeScreen = () => {
  const dimensions = useWindowDimensions();
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  useEffect(() => {
    const updateLayout = () => {
      setIsLargeScreen(
        dimensions.width > 768 && dimensions.width > dimensions.height
      );
    };
    updateLayout();
  }, []);

  return isLargeScreen;
};

export default useIsLargeScreen;
