import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, View, TextInput } from "react-native";
import { IconButton, useTheme } from "react-native-paper";
import { MessageType } from "../../types";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import axios from "axios";
import Constants from "expo-constants";

interface Props {
  onSendPress: (
    message: MessageType.PartialText | MessageType.PartialImage
  ) => void;
}

const MIN_HEIGHT = Platform.OS === "ios" ? 40 : 40;
const MAX_HEIGHT = Platform.OS === "ios" ? 128 : 128;

export default function ChatInput({ onSendPress }: Props) {
  const theme: ReactNativePaper.Theme = useTheme();
  const [text, onChangeText] = React.useState("");
  const [textboxHeight, setTextboxHeight] = React.useState(MIN_HEIGHT);
  const [loading, setLoading] = useState<boolean>(false);

  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "flex-end",
      backgroundColor: theme.colors.headerBgColor,
      padding: 16,
      overflow: "hidden",
      minHeight: MIN_HEIGHT,
      maxHeight: MAX_HEIGHT + 32,
      height: textboxHeight + 32
    },
    textInput: {
      flex: 1,
      backgroundColor: theme.colors.textbox,
      color: theme.colors.black,
      paddingTop: Platform.OS === "android" ? 0 : 10,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 0,
      fontSize: 16,
      lineHeight: 18,
      borderRadius: 20,
      height: textboxHeight,
      minHeight: MIN_HEIGHT,
      justifyContent: "center"
    },
    inputContent: {
      // height: textboxHeight,
      // minHeight: MIN_HEIGHT,
      // paddingVertical: 0,
    },
    inputOutline: {},
    sendButton: {
      padding: 0,
      margin: 0
    },
    photoButton: {
      padding: 0,
      margin: 0
    }
  });

  const handleContentSizeChange = e => {
    const { height } = e.nativeEvent.contentSize;
    if (height !== textboxHeight) {
      console.log(
        "OS: " +
          Platform.OS +
          " original: " +
          textboxHeight +
          ", new height: " +
          height
      );
      setTextboxHeight(Math.min(Math.max(height + 16, MIN_HEIGHT), MAX_HEIGHT));
    }
  };

  const uploadToMathpix = async (uri: string) => {
    setLoading(true);
    const API_URL = Constants?.expoConfig?.extra?.API_URL;
    const token = await axios.get(`${API_URL}/mathpix`);
    console.log("kth5 token", token.data.app_token);
    try {
      const res = await axios.post(
        "https://api.mathpix.com/v3/text",
        {
          src: uri,
          formats: "latex_styled",
          confidence_threshold: 0.8
        },
        {
          headers: {
            app_token: token.data.app_token
          }
        }
      );
      setLoading(false);
      console.log("kth res", res.data);
      return res;
    } catch (error) {
      console.log("kth error", error);
    }
  };

  //take picture with camera
  const takePicture = async () => {
    if (Platform.OS !== "web") {
      const { status } = await Permissions.askAsync(Permissions.CAMERA);
      if (status !== "granted") {
        alert("We need permission to use your camera");
        return;
      }
    }
    const chatCamera = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.7
    });

    const appendBase64Prefix = (base64: string) => {
      if (base64.startsWith("data:image/jpeg;base64,")) {
        return base64;
      }
      if (base64.startsWith("/9y/")) {
        // need to make sure this works, otherwise we might have to remove the 9y prefix
        return `data:image/jpeg;base64,${base64}`;
      }
      return `data:image/png;base64,${base64}`;
    };
    // convert image  file uri to base64

    if (!chatCamera.canceled) {
      const mathData = await uploadToMathpix(
        appendBase64Prefix(chatCamera.assets[0].base64)
      );
      const mathDataText = mathData.data.latex_styled;

      const imageMessage: MessageType.PartialImage = {
        name: "",
        type: "image",
        uri: appendBase64Prefix(chatCamera.assets[0].base64),
        size: 1,
        height: chatCamera.assets[0].height,
        width: chatCamera.assets[0].width,
        text: mathDataText
      };
      onSendPress(imageMessage);
    }
  };

  const handleSendPress = () => {
    if (text.trim() == "") return;
    const t: MessageType.PartialText = { text, type: "text" };
    onSendPress(t);
    onChangeText("");
  };

  const handleKeyDown = e => {
    if (Platform.OS === "web" && e.nativeEvent.key === "Enter") {
      if (e.nativeEvent.shiftKey) {
        onChangeText(text);
      } else {
        e.preventDefault();
        handleSendPress();
      }
    }
  };

  // return input field with send button like in the chat screen
  return (
    <View style={styles.container}>
      <TextInput
        style={styles.textInput}
        multiline={true}
        onChangeText={onChangeText}
        onSubmitEditing={handleSendPress}
        onContentSizeChange={handleContentSizeChange}
        onKeyPress={handleKeyDown}
        placeholder='Enter Message...'
        returnKeyType='send'
        autoFocus={false}
        clearButtonMode='while-editing'
        value={text}
        selectionColor={theme.colors.primary}
        placeholderTextColor={theme.colors.text2}
      />
      <IconButton
        onPress={takePicture}
        size={22}
        icon='camera'
        iconColor={theme.colors.icon}
        style={styles.photoButton}
      />
      <IconButton
        icon='send'
        iconColor={theme.colors.icon}
        onPress={handleSendPress}
        style={styles.sendButton}
      />
    </View>
  );
}
