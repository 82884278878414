import { Modal, Portal, useTheme, Text } from "react-native-paper";
import { StyleSheet, ViewStyle } from "react-native";
import useIsLargeScreen from "@hooks/useIsLargeScreen";
import { Dispatch, SetStateAction, ReactNode } from "react";

export default function BaseModal({
  children,
  title,
  visible,
  setVisible,
  style
}: {
  children: ReactNode;
  title: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  style?: ViewStyle;
}) {
  const theme: ReactNativePaper.Theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const styles = StyleSheet.create({
    modalContainer: {
      backgroundColor: theme.colors.white,
      padding: 20,
      paddingVertical: 40,
      borderRadius: 10,
      gap: 24,
      width: isLargeScreen ? 500 : "90%",
      alignSelf: "center"
    },
    modalTitle: {
      textAlign: "center",
      fontFamily: "PoppinsSemiBold"
    }
  });
  return (
    <Portal>
      <Modal
        visible={visible}
        contentContainerStyle={{ ...styles.modalContainer, ...style }}
        theme={{ colors: { backdrop: "#00000050" } }}
        onDismiss={() => setVisible(false)}
        style={style}
      >
        <Text variant='headlineSmall' style={styles.modalTitle}>
          {title}
        </Text>
        {children}
      </Modal>
    </Portal>
  );
}
