import { RootStackParamList } from "@components/navigations/types";
import PaperInView from "@components/papers/PaperInView";
import { Paper } from "@entities/index";
import { usePrint } from "@hooks/usePrint";
import type { StackScreenProps } from "@react-navigation/stack";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View, Platform } from "react-native";
import { useShare } from "@hooks/useShare";
import Container from "typedi";
import { DataSource } from "typeorm";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import { useTextSize } from "@hooks/useTextSize";
import { useRegenerate } from "@hooks/useRegenerate";
import { useDownload } from "@hooks/useDownload";
import { useShowHeaders } from "@hooks/useShowHeaders";
import LoadingModal from "@components/papers/LoadingModal";
import PaperRegenerationModal from "@components/papers/PaperRegenerationModal";
import ReorderModal from "@components/papers/ReorderModal";
import useIsLargeScreen from "@hooks/useIsLargeScreen";

type PaperScreenProps = StackScreenProps<RootStackParamList, "PaperScreen">;

const PaperScreen = ({ navigation, route, ...rest }: PaperScreenProps) => {
  const { listen } = useDSSubscriber();
  const { getFontSize } = useTextSize();
  const { print } = usePrint();
  const { share } = useShare();
  const { download } = useDownload();
  const { regenerate } = useRegenerate();
  const { getShowHeaders } = useShowHeaders();
  const [paper, setPaper] = useState<Paper>();
  const [paperRegenerating, setPaperRegenerating] = useState<boolean>(false);
  const [paperReordering, setPaperReordering] = useState<boolean>(false);
  const [confirmationVisible, setConfirmationVisible] =
    useState<boolean>(false);
  const [additionalPrompt, setAdditionalPrompt] = useState<string>("");
  const [retainAnswer, setRetainAnswer] = useState<boolean>(false);
  const [retainQuestionType, setRetainQuestionType] = useState<boolean>(false);
  const [fontSizeState, setFontSizeState] = useState<number>(getFontSize());
  const [showHeaders, setShowHeaders] = useState<boolean>(getShowHeaders());
  const isLargeScreen = useIsLargeScreen();
  listen("FontSize", () => setFontSizeState(getFontSize()));
  listen("ShowHeaders", () => setShowHeaders(getShowHeaders()));

  // Styles for the screen
  const styles = StyleSheet.create({
    container: {
      backgroundColor: "#FEEDE4"
    },
    containerContent: {
      flexGrow: 1,
      padding: 16,
      width: "100%"
    },
    paper: {
      flex: 1,
      justifyContent: "flex-start",
      paddingHorizontal: isLargeScreen ? 20 : 12,
      paddingVertical: isLargeScreen ? 16 : 12,
      backgroundColor: "#fff",
      borderRadius: 12,
      width: "100%"
    }
  });

  // Fetch paper details
  useEffect(() => {
    console.log("paper id in paper screen is", route.params.paperId);
    const fetchPaper = async () => {
      const datasource = Container.get(DataSource);
      const paper = await datasource.getRepository(Paper).findOne({
        where: { id: route.params.paperId },
        relations: ["sections.questions"],
        order: {
          sections: {
            index: "ASC",
            questions: {
              index: "ASC"
            }
          }
        }
      });
      if (!paper) return;
      setPaper(paper);
      console.log("fetched paper", paper);
    };
    fetchPaper();
    return listen("PaperDetails", fetchPaper);
  }, [route.params.paperId]);

  // Handle paper screen navigation header button actions.
  useEffect(() => {
    if (!route.params.print) return;
    navigation.setParams({ print: false });
    print(paper!.toHtml(fontSizeState, showHeaders));
  }, [route.params?.print]);

  useEffect(() => {
    if (!route.params.share) return;
    navigation.setParams({ share: false });
    share(paper!.toHtml(fontSizeState, showHeaders));
  }, [route.params?.share]);

  useEffect(() => {
    if (!route.params.download) return;
    navigation.setParams({ download: false });
    download(paper!.toHtml(fontSizeState, showHeaders), paper!.title);
  }, [route.params?.download]);

  useEffect(() => {
    if (!route.params.reorder) return;
    navigation.setParams({ reorder: false });
    setPaperReordering(true);
  }, [route.params?.reorder]);

  useEffect(() => {
    if (!route.params.confirmRegenerate) return;
    navigation.setParams({ confirmRegenerate: false });
    setConfirmationVisible(true);
  }, [route.params?.confirmRegenerate]);

  // Handle paper regeneration after user confirmation
  useEffect(() => {
    if (!paperRegenerating) {
      setPaperRegenerating(false);
      return;
    }
    regenerate(
      paper!,
      additionalPrompt,
      retainAnswer,
      retainQuestionType,
      () => {
        setPaperRegenerating(false);
        if (Platform.OS !== "web") navigation.goBack();
      }
    );
  }, [confirmationVisible]);

  if (!paper) return null;

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.containerContent}
      automaticallyAdjustsScrollIndicatorInsets={false}
    >
      <View style={styles.paper}>
        <LoadingModal visible={paperRegenerating} />
        <PaperRegenerationModal
          visible={confirmationVisible}
          setPaperRegenerating={setPaperRegenerating}
          setConfirmationVisible={setConfirmationVisible}
          additionalPrompt={additionalPrompt}
          setAdditionalPrompt={setAdditionalPrompt}
          retainAnswer={retainAnswer}
          setRetainAnswer={setRetainAnswer}
          retainQuestionType={retainQuestionType}
          setRetainQuestionType={setRetainQuestionType}
        />
        <ReorderModal
          key={`reorder-${JSON.stringify(paper)}`}
          paper={paper}
          visible={paperReordering}
          setPaperReordering={setPaperReordering}
        />
        {/* We stringify the paper and use it as a key to make sure the paper properly re-renders upon edits or refreshes */}
        {/* Probably better to use the updatedAt field but there's currently an issue with that */}
        {/* See https://gitlab.cpu.edu.hk/cpuer-io/youcodia-synthesizer/-/issues/135 */}
        <PaperInView
          key={JSON.stringify(paper)}
          paper={paper}
          showHeaders={showHeaders}
        />
      </View>
    </ScrollView>
  );
};

export default PaperScreen;
