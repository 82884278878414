import { RootStackParamList } from "@components/navigations/types";
import PaperSectionListItem from "@components/papers/PaperSectionListItem";
import ScanPaperButton from "@components/papers/CreatePaperButton";
import { Paper, PaperStatus } from "@entities/index";
import { useDSSubscriber } from "@hooks/useDSSubscriber";
import Container from "typedi";
import { DataSource } from "typeorm";
import { StackScreenProps } from "@react-navigation/stack";
import { useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { useTheme } from "react-native-paper";
import { Not } from "typeorm";

type Props = StackScreenProps<RootStackParamList, "PaperListScreen">;

const PaperListScreen = ({ navigation }: Props) => {
  const theme: ReactNativePaper.Theme = useTheme();
  const [papers, setPapers] = useState<Paper[]>([]);
  const [loading, setLoading] = useState(true);
  const { listen } = useDSSubscriber();

  useEffect(() => {
    const fetchPapers = async () => {
      const datasource = Container.get(DataSource);
      const papers =
        (await datasource.getRepository(Paper).find({
          where: { status: Not(PaperStatus.Archived) },
          relations: ["sections.questions"]
        })) ?? [];
      papers.reverse();
      setPapers(papers);
      setLoading(false);
    };
    fetchPapers();
    return listen("Papers", fetchPapers);
  }, []);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.black,
      height: "100%",
      width: "100%"
    }
  });

  console.log("papers in PaperListScreen", papers);

  return (
    <View style={styles.container}>
      <ScanPaperButton />
      <View>
        {loading ? (
          <ActivityIndicator />
        ) : (
          papers.map(paper => (
            <PaperSectionListItem key={paper.id} paper={paper} />
          ))
        )}
      </View>
    </View>
  );
};

export default PaperListScreen;
